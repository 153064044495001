section#quick-ref {
  background-image: url("../../images/layout/bkg_generic_light@2x.jpg");
}
section#cyber-quick-ref {
  background-image: url("../../images/backgrounds/Stage_6_Closing.jpg");
  .asset-page-number {
    display: flex;
    margin: 0 auto;
    background-image: url(/images/hexagon_6.svg);
    background-size: contain;
    background-repeat: no-repeat;
    color: #fff;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 10px;
    font-weight: 900;
    align-content: center;
    justify-content: center;
    padding-left: 1px;
  }
  @media (min-width: $screen-sm-min) {
    .asset-page-number {
      width: 45px;
      height: 45px;
      line-height: 45px;
      font-size: 20px;
    }
  }

  @media (min-width: $screen-lg-min) {
    .asset-page-number {
      width: 45px;
      height: 45px;
      line-height: 45px;
      font-size: 20px;
    }
    p.cyber-recovery {
      font-size: 18px !important;
      line-height: 24px;
      letter-spacing: 0.9px;
      padding-top: 19px;
      padding-bottom: 24px;
      font-weight: 400 !important;
    }
  }

  @media (min-width: $screen-xl-min) {
    .asset-page-number {
      width: 65px;
      height: 65px;
      line-height: 65px;
      font-size: 32px;
      margin-bottom: 18px;
    }
  }
}
section#quick-ref,
section#cyber-quick-ref {
  background-color: #f3f3f3;
  background-position: center center;
  background-size: cover;
  min-height: 100vh;
  font-family: roboto, sans-serif;
  flex-flow: column;
  justify-content: space-between;
  padding-top: 0;

  .quick-ref-container {
    .quick-ref-options-container {
      margin: 0 auto;

      .title-container {
        width: 80%;
        margin: 0 auto 30px;

        h1 {
          color: #444;
          font-size: 24px;
          letter-spacing: 7px;
          text-transform: uppercase;
          text-align: center;
          font-family: roboto, sans-serif;
          font-weight: 900;
          margin: 0 0 30px 0;
        }

        p {
          color: #444;
          font-size: 12px;
          font-weight: bold;
          text-transform: uppercase;
          font-family: roboto, sans-serif;
          margin-bottom: 10px;
        }
      }

      .quick-ref-options {
        margin: 0 auto;
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        grid-template-rows: 1fr 1fr 1fr;
        gap: 20px 20px;
        grid-template-areas: ". ." ". ." ". .";

        .quick-ref-ind {
          display: flex;
          flex-direction: column;
          width: 100%;
          text-decoration: none;

          .video-section {
            height: 88px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .gif-preview {
              transition: opacity 0.2s ease;
              opacity: 0;
              object-fit: cover;
              object-position: center;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
            }

            .image-preview {
              transition: opacity 0.2s ease;
              opacity: 1;
              object-fit: cover;
              object-position: center;
              height: 100%;
              width: 100%;
            }

            .octagon {
              width: 65px;
              height: 65px;
              display: block;
              position: absolute;
              background-image: url("../../images/svg/octagon.svg");

              .octagon-poly {
                stroke: transparent;
                fill: transparent;
              }
            }
          }

          .quick-ref-info {
            transition: background-color 0.2s ease;
            background-color: rgba(255, 255, 255, 0.9);
            text-align: center;
            height: 67%;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            p.preview-link,
            h2.quick-ref-title {
              color: #444;
              padding-bottom: 24px;
              margin: 0;
              padding-right: 5px;
              padding-left: 5px;
              transition: color 0.2s ease;
              font-family: roboto, sans-serif;
            }

            p.preview-link {
              font-size: 12px;
              color: #0076ce;
              font-weight: 400;
              line-height: 1;

              .launch-icon {
                width: 20px;
                margin-left: 10px;
                vertical-align: baseline;
              }

              &.launch-site .launch-icon {
                transform: translateY(5px);
              }
            }

            h2.quick-ref-title {
              font-size: 15px;
              text-transform: uppercase;
              letter-spacing: 1.5px;
              padding-top: 24px;
            }
          }

          &:hover {
            .quick-ref-info {
              background-color: rgba(255, 255, 255, 1);
            }
          }
        }
      }
    }
  }

  @media (min-width: $screen-sm-min) {
    .quick-ref-container {
      .quick-ref-options-container {
        max-width: 631px;

        .title-container {
          width: 100%;

          h1 {
            line-height: 1.2;
            font-size: 32px;
            margin-bottom: 40px;
            letter-spacing: 7.6px;
          }
        }

        .quick-ref-options {
          gap: 45px 45px;

          .quick-ref-ind {
            .video-section {
              height: 159px;
              min-height: 159px;
            }

            .quick-ref-info {
              padding: 0;
              height: 55%;

              h2.quick-ref-title {
                padding-top: 34px;
                font-size: 20px;
                letter-spacing: 2px;
                line-height: 28px;
              }

              p.preview-link {
                font-size: 16px;
                padding-bottom: 27px;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: $screen-md-min) {
    .quick-ref-container {
      .quick-ref-options-container {
        max-width: 960px;

        .title-container {
        }

        .quick-ref-options {
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-rows: 1fr 1fr;

          .quick-ref-ind {
            .video-section {
            }

            .quick-ref-info {
              .quick-ref-title {
              }

              .preview-link {
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: $screen-lg-min) {
    .quick-ref-container {
      .quick-ref-options-container {
        .title-container {
        }

        .quick-ref-options {
          .quick-ref-ind {
            .video-section {
            }

            .quick-ref-info {
              .quick-ref-title {
              }

              .preview-link {
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 1366px) {
    .quick-ref-container {
      .quick-ref-options-container {
        max-width: 1280px;

        .title-container {
          max-width: 80%;
          margin: 0 auto;
        }

        .quick-ref-options {
          .quick-ref-ind {
            .video-section {
            }

            .quick-ref-info {
              h2.quick-ref-title {
                letter-spacing: 1.2px;
              }

              p.preview-link {
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: $screen-xl-min) {
    .quick-ref-container {
      .quick-ref-options-container {
        max-width: 1420px;

        .title-container {
          max-width: 90%;

          h1 {
            font-size: 43px;
            line-height: 53px;
            letter-spacing: 12px;
            margin-bottom: 60px;
          }

          p {
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 500;
          }
        }

        .quick-ref-options {
          .quick-ref-ind {
            .video-section {
              height: 180px;
              min-height: 180px;
            }

            .quick-ref-info {
              padding: 0 20px;

              h2.quick-ref-title {
                padding-top: 40px;
                padding-bottom: 24px;
              }

              p.preview-link {
                padding-bottom: 43px;
              }
            }
          }
        }
      }
    }
  }
}
