header#masthead {
	position: relative;
	width: 100%;

	.logo {
		position: relative;
		top: 0;
		left: 0;
		float: left;

		@media (max-width: $screen-xs-max) {
			padding: 30px 20px;
		}

		svg, img {
			width: 100px;
			height: 29px;
		}
	}

	nav {
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 1000;
		background: $gray-dark;
		color: #fff;
		text-align: center;
		display: flex;
		flex-flow: row;

		@media (max-width: $screen-xs-max) {
			width: 100%;
			padding: 0;
		}

		img, svg {
			display: block;
			height: 20px;
			margin: 0 auto 7px;
		}

		a {
			color: #fff;
			font-weight: 400;
			font-size: 12px;
			text-align: center;
			text-decoration: none;
			display: inline-block;
			padding: 11px 0 8px 0;
			line-height: 14px;
			flex-grow: 1;

			&:hover {
				background-color: $brand-primary;
			}

		}


	}

	@media (max-width: $screen-xs-max) {
		&.break nav a:first-child {
			border-right: 1px solid #eee;
		}
	}

	/** TABLET STYLES **/
	@media (min-width: $screen-sm-min) {

		.logo {
			position: relative;
			padding-top: 30px;
			left: 45px;

			svg, img {
				width: 158px;
				height: auto;
			}
		}

		nav {
			position: relative;
			top: 0;
			right: 30px;
			left: auto;
			padding: 30px calc(var(--bs-gutter-x) / 2) 20px;
			background: transparent;
			display: inline-block;
			text-align: right;

			img, svg {
				height: 35px;
				margin-bottom: 13px;
			}

			a {
				position: relative;
				display: inline-block;
				padding: 0;
				margin-right: 40px;
				width: auto;
				font-size: 15px;
				max-width: 110px;
				line-height: 18px;

				&:first-child {
					border-right: none;
				}

				&:last-child {
					margin-right: 0;
				}

				span {
					position: relative;
				}

				span:after {
					content: "";
					display: block;
					width: 100%;
					height: 3px;
					background-color: transparent;
					margin-top: 8px;
					transition: background-color 0.25s;
				}

				&:hover {
					background-color: transparent;

					span:after {
						background-color: #fff;
					}
				}

				&:active {
					background-color: transparent;
				}

			}
		}

		// Blue Theme
		&.blue {
			nav a {
				color: $gray-dark;

				&:hover {
					color: $brand-primary;


				  span:after {
					background-color: $brand-primary;
				  }
				}

			}

			svg path {
				fill: $brand-primary;
			}
		}

	}


	/** LAPTOP STYLES **/
	@media (min-width: $screen-lg-min) {

		.logo {
			padding-top: 35px;
			left: 50px;

			svg, img {
				width: 180px;
				height: auto;
			}
		}

		nav {
			right: 50px;
			padding: 35px calc(var(--bs-gutter-x) / 2) 0;
		}
	}


	/** DESKTOP STYLES **/
	@media (min-width: $screen-xl-min) {

		.logo {
			padding-top: 50px;

			svg, img {
				width: 256px;
				height: auto;
			}
		}

		nav {
			padding: 50px calc(var(--bs-gutter-x) / 2) 0;
		}
	}

}
