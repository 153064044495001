section#video {
	padding: 0;

	.splash-image {
		position: absolute;
		width: 100vw;
		height: 100vh;
		background-size: cover;
		background-position: center;
	}

	.dell-logo {
		width: 100px;
		z-index: 1;
		position: absolute;
		top: 30px;
		left: 20px;
	}

	.intel-logo {
		width: 100px;
		z-index: 1;
		position: absolute;
		top: 32px;
		right: 30px;
	}

	.dell-logo, .intel-logo {
		-webkit-transition: opacity 0.5s ease-in-out;
		-moz-transition: opacity 0.5s ease-in-out;
		-ms-transition: opacity 0.5s ease-in-out;
		-o-transition: opacity 0.5s ease-in-out;
		opacity: 1;
	}

	video::-webkit-media-controls-fullscreen-button {
		display: none;
	}

	.heading {
		font-size: 16px;
		line-height: 1.3em;
		position: absolute;
		top: 32%;
		left: 50%;
		text-align: center;
		transform: translate(-50%, -50%);
		text-transform: uppercase;
	}

	.arrow-right {
		width: 0;
		height: 0;
		border-top: 10px solid transparent;
		border-bottom: 10px solid transparent;
		border-left: 18px solid #fff;
		position: relative;
		left: 50%;
		transform: translate(-50%,-50%);
		top: 50%;
		margin-left: 3px;
	}

	.octagon {
		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;
		overflow: hidden;
		transform: rotate(45deg);
		border: 3px solid white;
		transition : border 200ms ease-out, background-color 200ms ease-out;
	}
	.octagon:before {
		position: absolute;
		/* There needs to be a negative value here to cancel
		 * out the width of the border. It's currently -3px,
		 * but if the border were 5px, then it'd be -5px.
		 */
		top: -3px; right: -3px; bottom: -3px; left: -3px;
		transform: rotate(45deg);
		content: '';
		border: inherit;
	}

	.octagonWrap {
		width: 75px;
		height: 75px;
		float: left;
		position: absolute;
		top: 65%;
		left: 50%;
		transform: translate(-50%,-50%);
		overflow: hidden;
		cursor: pointer;

		&:hover {
			.octagon {
				background-color: #0076CE;
				border-color: #0076CE;
			}
		}
	}

	#video-endpoint {
		.skip-button {
			right: 0;
			left: auto;
			top: 60px;
			bottom: 10px;
			width: 90px;
			height: 90px;
			z-index: 100;
		}

		.octagon {
			border: none;
		}

		img {
			width: 34px;
			position: relative;
			left: 50%;
			transform: translateX(-50%);
			top: 15px;
		}

		span {
			text-decoration: none;
			color: #fff;
			position: absolute;
			top: 55px;
			left: 50%;
			transform: translateX(-50%);
			line-height: 1em;
		}
	}

	&.splash-screen {
		width: 100vw;
		height: 100vh;
		background: grey;
	}

	.video-skin {
		display: block;
		vertical-align: top;
		box-sizing: border-box;
		color: #fff;
		position: relative;
		padding: 0;
		font-size: 10px;
		line-height: 1;
		font-weight: 400;
		font-style: normal;
		font-family: Arial,Helvetica,sans-serif;
		word-break: initial;
		height: calc(100vw / 1.7777);
		width: calc(100vh * 1.7777);
		max-width: 100vw;
		max-height: 100vh;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	// TABLET AND MOBILE ONLY
	@media (max-width: $screen-sm-max) and (orientation:portrait) {
		#video-endpoint {
			width: 100vw;
			background: #444444;
			height: 60px;
			display: inline-block;
			position: absolute;
			bottom: 0;

			.skip-button {
				right: auto;
				top: 25px;
				bottom: 10px;
				width: 90px;
				height: 60px;
				left: 50%;
			}

			img {
				width: 25px;
				position: relative;
				left: 50%;
				transform: translateX(-50%);
				top: 12px;
			}

			span {
				font-size: 12px;
				text-decoration: none;
				color: #fff;
				position: absolute;
				top: 42px;
				left: 50%;
				transform: translateX(-50%);
				line-height: 1em;
			}
		}
	}

	@media (max-width: $screen-sm-max) and (orientation:landscape) {
		#video-endpoint {
			.skip-button {
				right: -40px;
			}
		}

		.dell-logo, .intel-logo {
			&.hide {
				opacity: 0;
			}
		}
	}


	// TABLET
	@media (min-width: $screen-sm-min) {
		.dell-logo {
			width: 158px;
			left: 57px;
		}

		.intel-logo {
			width: 158px;
			right: 45px;
		}

		.heading {
			font-size: 20px;
		}

		.octagonWrap {
			width: 100px;
			height: 100px;
			top: 63%;
		}

		.arrow-right {
			border-top: 14px solid transparent;
			border-bottom: 14px solid transparent;
			border-left: 24px solid #fff;
		}
	}

	// LAPTOP
	@media (min-width: $screen-md-min) {

		.dell-logo {
			left: 57px;
		}

		.dell-logo, .intel-logo {
			&.hide {
				opacity: 0;
			}
		}

		.octagonWrap {
			top: 60%;
		}
	}

	// DESKTOP
	@media (min-width: $screen-lg-min) {

		.dell-logo {
			width: 180px;
			top: 35px;
			left: 62px;
			transform: none;
		}

		.intel-logo {
			width: 200px;
			bottom: 57px;
			right: 50px;
			top: 55px;
		}

		.heading {
			font-size: 22px;
			top: 50%;
			transform: translateY(-50%);
			margin: 0 0 0 62px;
			text-align: left;
			max-width: 500px;
			left: auto;
			letter-spacing: 8px;
		}

		.octagonWrap {
			top: 50%;
		}
	}

	// DESKTOP
	@media (min-width: 1700px) {
		.heading {
			font-size: 36px;
			max-width: 800px;
			letter-spacing: 11px;
		}
	}

	// DESKTOP
	@media (min-width: $screen-xl-min) {

		.dell-logo {
			width: 256px;
			top: 50px;
			left: 62px;
			transform: none;
		}
	}

}
