nav.stage-navigation {
  .ui-left,
  .ui-right {
    height: 18px;
    position: absolute;
  }

  .ui-right {
    right: 20px;
  }

  .ui-left {
    left: 20px;
  }

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: $screen-md-min) {
    .btn {
      &.prev-stage { text-align: right; }
      &.next-stage { text-align: left; }
    }
  }
}
