/* Overrides
-------------------------------------------------------- */
.control-label {
  &.text-left { text-align: left; }
  small { font-weight: normal; }
}

.btn {
  transition: all $animation-speed ease;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  outline: none !important;

  &.text-left { text-align: left; }
  &.text-right { text-align: right; }

  &:active, &.active {
    box-shadow: none;
  }
}

.panel {
  box-shadow: 0 1px 5px 3px rgba(#000, 0.05);

  /* Medium devices (desktops, 992px and up) */
  @media (max-width: $screen-md-min) {
    .panel-body { padding: 20px; }
  }
}

/* Form input
-------------------------------------------------------- */
textarea {
  resize: vertical;
  min-height: 150px;
  max-height: 300px;
}

.form-control {
  transition: box-shadow $animation-speed ease;
  box-shadow: 0 0 0 0 rgba($input-border-focus, 0.4);

  &:focus {
    box-shadow: 0 0 0 2px rgba($input-border-focus, 0.4);
  }
}

$checkbox: 22px;

input[type="checkbox"],
input[type="radio"] {
  border: 1px solid $input-border;
  position: relative;
  background: $input-bg;
  clear: none;
  cursor: pointer;
  display: inline-block;
  line-height: 0;
  height: $checkbox;
  margin: -($checkbox / 4) ($checkbox / 4) 0 0;
  outline: none !important;
  padding: 0;
  text-align: center;
  vertical-align: middle;
  width: $checkbox;
  min-width: $checkbox;
  -webkit-appearance: none;
  transition: box-shadow $animation-speed ease;
  border-radius: 0;

  &:focus {
    box-shadow: 0 0 0 2px rgba($input-border-focus, 0.4);
    border-color: $input-border-focus;
  }

  &:checked {
    border-color: $input-border-focus;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

