.styled-dropdown {
	position: relative;
	height: 45px;
	border: solid 1.5px #fff;
	overflow: hidden;
	color: $brand-success;

	span.title {
		color: #fff;
	}

	span {
		display: block;
		width: 100%;
		height: 100%;
		padding: 11px 9px;
		font-weight: 200;
		font-size: 14px;
		cursor: pointer;

		&::after {
			content: url("../../images/icons/icon-chev-down-light.png");
			position: absolute;
			right: 9px;
		}
	}

	ul {
		position: relative;
		background: #fff;
		color: $brand-success;
		margin: 0 -1.5px;
		z-index: 100;
		max-height: 180px;
		display: none;

		li {
			padding: 7px 9px;
			font-size: 14px;
			cursor: pointer;
			border: solid white;
			border-width: 0 1.5px 0 1.5px;
			margin: 0;
			color: $brand-success;

			&::before {
				content: none;
			}

			&:hover {
				background: darken($brand-success, 5%);
				color: #fff;
			}

			&:last-of-type {
				border-width: 0 1.5px 1.5px 1.5px;
			}
		}
	}

	&.open {
		overflow: visible;

		ul {
			display: block;
		}
	}

	// MOBILE ONLY
	@media (max-width: $screen-xs-max) {
		margin-bottom: 20px;
	}

	// DESKTOP
	@media (min-width: $screen-lg-min) {
		ul {
			max-height: 30vh;
		}
	}
}

// COLUMN WRAPPER: TABLET - SMALL DESKTOP
@media (min-width: $screen-sm-max) and (max-width: 1400px) {

	.styled-dropdown-col {
		width: 100%;
		max-width: 100%;
		flex: 0 0 100%;
		padding-bottom: 25px;
	}
}
