@charset "UTF-8";

/* Project variables
-------------------------------------------------------- */

/*
*
* ============================================================================
* Custom Variables
* ============================================================================
*
*/

/*
*
* ============================================================================
* FontAwesome Variables
* ============================================================================
*
*/

/*
*
* ============================================================================
* Bootstrap Variables
* ============================================================================
*
*/

/*
*
* ============================================================================
* WordPress admin variables
* ============================================================================
*
*/

@import url(vendor/simplebar.css);

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif;
  margin: 0;
}

body > main {
  opacity: 0;
  transition: opacity 1s ease;
}

body > .loading {
  opacity: 1;
  transition: opacity 1s ease;
}

body.page-loaded > main {
  opacity: 1;
}

body.page-loaded > .loading {
  opacity: 0;
  z-index: -1;
}

/* Silver bootstrapping
-------------------------------------------------------- */

/* CSS3 bg cover IE support
-------------------------------------------------------- */

/* CSS3 bg contain IE support
-------------------------------------------------------- */

/* Position abolsute, fill parent element
-------------------------------------------------------- */

/* Height hack (e.g. headers)
-------------------------------------------------------- */

/* Easily generate an overlay on an element
-------------------------------------------------------- */

/* CSS blur with IE support
-------------------------------------------------------- */

/* Generate a keylined version of a bootstrap button
-------------------------------------------------------- */

/* Calculate a responsive font size
-------------------------------------------------------- */

/* Vertical align
-------------------------------------------------------- */

.vertical-align {
  display: table;
  width: 100%;
  height: 100%;
}

.vertical-align > .middle {
  display: table-cell;
  vertical-align: middle;
}

.vertical-align > .bottom {
  display: table-cell;
  vertical-align: bottom;
}

/* Remove WYSIWYG spacing
-------------------------------------------------------- */

.content-margins > :first-child {
  margin-top: 0;
}

.content-margins > :last-child {
  margin-bottom: 0;
}

/* Remove padding on bootstrap columns
-------------------------------------------------------- */

.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

/* Extra small devices (phones, 0 and up) */

.no-xs-padding {
  padding-left: 0;
  padding-right: 0;
}

.no-xs-padding-left {
  padding-left: 0;
}

.no-xs-padding-right {
  padding-right: 0;
}

/* Small devices (tablets, 768px and up) */

/* Medium devices (desktops, 992px and up) */

/* Large devices (large desktops, 1200px and up) */

/* Bootstrap 5th columns
-------------------------------------------------------- */

.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
  position: relative;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px;
}

.col-xs-5ths {
  width: 20%;
  float: left;
}

/* Device specific breaks
-------------------------------------------------------- */

/* Collapsed Panels
-------------------------------------------------------- */

/* Vendor imports
-------------------------------------------------------- */

/* Ion.RangeSlider
// css version 2.0.3
// © 2013-2014 Denis Ineshin | IonDen.com
// ===================================================================================================================*/

/* =====================================================================================================================
// RangeSlider */

.irs {
  position: relative;
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.irs-line {
  position: relative;
  display: block;
  overflow: hidden;
  outline: none !important;
}

.irs-line-left,
.irs-line-mid,
.irs-line-right {
  position: absolute;
  display: block;
  top: 0;
}

.irs-line-left {
  left: 0;
  width: 11%;
}

.irs-line-mid {
  left: 9%;
  width: 82%;
}

.irs-line-right {
  right: 0;
  width: 11%;
}

.irs-bar {
  position: absolute;
  display: block;
  left: 0;
  width: 0;
}

.irs-bar-edge {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
}

.irs-shadow {
  position: absolute;
  display: none;
  left: 0;
  width: 0;
}

.irs-slider {
  position: absolute;
  display: block;
  cursor: default;
  z-index: 1;
}

.irs-slider.type_last {
  z-index: 2;
}

.irs-min {
  position: absolute;
  display: block;
  left: 0;
  cursor: default;
}

.irs-max {
  position: absolute;
  display: block;
  right: 0;
  cursor: default;
}

.irs-from,
.irs-to,
.irs-single {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  cursor: default;
  white-space: nowrap;
}

.irs-grid {
  position: absolute;
  display: none;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
}

.irs-with-grid .irs-grid {
  display: block;
}

.irs-grid-pol {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 8px;
  background: #000;
}

.irs-grid-pol.small {
  height: 4px;
}

.irs-grid-text {
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  text-align: center;
  font-size: 9px;
  line-height: 9px;
  padding: 0 3px;
  color: #000;
}

.irs-disable-mask {
  position: absolute;
  display: block;
  top: 0;
  left: -1%;
  width: 102%;
  height: 100%;
  cursor: default;
  background: rgba(0, 0, 0, 0);
  z-index: 2;
}

.irs-disabled {
  opacity: 0.4;
}

.lt-ie9 .irs-disabled {
  filter: alpha(opacity=40);
}

.irs-hidden-input {
  position: absolute !important;
  display: block !important;
  top: 0 !important;
  left: 0 !important;
  width: 0 !important;
  height: 0 !important;
  font-size: 0 !important;
  line-height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  outline: none !important;
  z-index: -9999 !important;
  background: none !important;
  border-style: solid !important;
  border-color: transparent !important;
}

/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Typography
-------------------------------------------------------- */

.serif {
  font-family: Georgia, "Times New Roman", Times, serif;
}

.sans-serif {
  font-family: "Roboto", sans-serif;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  color: #fff;
}

p,
ul,
li {
  font-family: "Roboto", sans-serif;
}

h1,
.h1 {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 7.2px;
  margin: 24px 0;
}

h2,
.h2 {
  font-size: calc(24px + (60 - 24) * ((100vw - 320px) / (1920 - 320)));
  margin: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320))) 0;
}

h3,
.h3 {
  font-size: calc(22px + (57 - 22) * ((100vw - 320px) / (1920 - 320)));
  margin: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320))) 0;
}

h4,
.h4 {
  font-size: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
  color: #22C5BE;
  margin: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320))) 0;
}

h5,
.h5 {
  font-size: calc(16px + (25 - 16) * ((100vw - 320px) / (1920 - 320)));
  font-weight: 400;
  margin: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320))) 0;
}

h6,
.h6 {
  font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
  color: #22C5BE;
  margin: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320))) 0;
}

p {
  color: #fff;
  font-weight: 400;
}

.highlight {
  color: #FF00CE;
}

p.medium,
span.medium {
  font-weight: 400;
}

p.success,
span.success {
  color: #22C5BE;
}

b,
strong {
  font-weight: 700;
}

a {
  font-weight: bold;
}

section {
  padding-top: 130px;
}

.text-black {
  color: #000;
}

.text-black p,
.text-black ul li,
.text-black ol li,
.text-black h1,
.text-black h2,
.text-black h3,
.text-black h5 {
  color: #000;
}

.section-title {
  margin-top: 0;
}

/* List Styling
-------------------------------------------------------- */

ul,
ol {
  color: #fff;
  font-weight: 400;
}

ul.black,
ol.black {
  color: #000;
}

ol {
  counter-reset: item;
  padding-left: 0;
}

ol li,
ul li {
  display: block;
  margin-bottom: 5px;
  clear: both;
  position: relative;
}

ol li::before,
ul li::before {
  display: inline-block;
  height: 25px;
  float: left;
}

ol li:before {
  content: counter(item) ". ";
  counter-increment: item;
  color: #22C5BE;
  padding-right: 10px;
  font-weight: 700;
}

ul {
  list-style: none;
  padding-left: 0;
}

ul li::before {
  content: "\2022";
  color: #22C5BE;
  padding-right: 10px;
  font-weight: 700;
}

/* Buttons
-------------------------------------------------------- */

button,
a.btn {
  display: inline-block;
  box-shadow: none;
  background: #0076CE;
  color: #fff;
  border-radius: 0;
  text-transform: none;
  padding: 11px 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.5;
  margin: 30px 20px 0 0;
  align-items: flex-start;
  font-family: "Roboto", sans-serif;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #0076CE;
  transition: all 0.25s ease;
}

button img,
a.btn img {
  transition: all 0.25s ease;
}

button:hover,
a.btn:hover {
  color: #fff;
  background: #006cc3;
}

button.secondary,
a.btn.secondary {
  background: transparent;
  border-color: #fff;
}

button.secondary:hover,
a.btn.secondary:hover {
  background: #fff;
  color: #0076CE;
}

button.transparent,
a.btn.transparent {
  background: transparent;
  border: none;
  padding-left: 0;
}

button.transparent:active,
button.transparent:focus,
a.btn.transparent:active,
a.btn.transparent:focus {
  outline: none;
}

button.open img,
a.btn.open img {
  transform: translate(5px, -2px) rotate(180deg) !important;
}

.fade-left {
  animation: animationFadeleft ease 1s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards;
  /*when the spec is finished*/
  -webkit-animation: animationFadeleft ease 1s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards;
  /*Chrome 16+, Safari 4+*/
  -moz-animation: animationFadeleft ease 1s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode: forwards;
  /*FF 5+*/
  -o-animation: animationFadeleft ease 1s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode: forwards;
  /*Not implemented yet*/
  -ms-animation: animationFadeleft ease 1s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode: forwards;
  /*IE 10+*/
}

@keyframes animationFadeleft {
  0% {
    opacity: 0;
    transform: translate(79px, 0px);
  }

  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@-moz-keyframes animationFadeleft {
  0% {
    opacity: 0;
    -moz-transform: translate(79px, 0px);
  }

  100% {
    opacity: 1;
    -moz-transform: translate(0px, 0px);
  }
}

@-webkit-keyframes animationFadeleft {
  0% {
    opacity: 0;
    -webkit-transform: translate(79px, 0px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
  }
}

@-o-keyframes animationFadeleft {
  0% {
    opacity: 0;
    -o-transform: translate(79px, 0px);
  }

  100% {
    opacity: 1;
    -o-transform: translate(0px, 0px);
  }
}

@-ms-keyframes animationFadeleft {
  0% {
    opacity: 0;
    -ms-transform: translate(79px, 0px);
  }

  100% {
    opacity: 1;
    -ms-transform: translate(0px, 0px);
  }
}

.fade-right {
  animation: animationFaderight ease 1s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards;
  /*when the spec is finished*/
  -webkit-animation: animationFaderight ease 1s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards;
  /*Chrome 16+, Safari 4+*/
  -moz-animation: animationFaderight ease 1s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode: forwards;
  /*FF 5+*/
  -o-animation: animationFaderight ease 1s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode: forwards;
  /*Not implemented yet*/
  -ms-animation: animationFaderight ease 1s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode: forwards;
  /*IE 10+*/
}

@keyframes animationFaderight {
  0% {
    opacity: 0;
    transform: translate(-80px, 0px);
  }

  100% {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@-moz-keyframes animationFaderight {
  0% {
    opacity: 0;
    -moz-transform: translate(-80px, 0px);
  }

  100% {
    opacity: 1;
    -moz-transform: translate(0px, 0px);
  }
}

@-webkit-keyframes animationFaderight {
  0% {
    opacity: 0;
    -webkit-transform: translate(-80px, 0px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(0px, 0px);
  }
}

@-o-keyframes animationFaderight {
  0% {
    opacity: 0;
    -o-transform: translate(-80px, 0px);
  }

  100% {
    opacity: 1;
    -o-transform: translate(0px, 0px);
  }
}

@-ms-keyframes animationFaderight {
  0% {
    opacity: 0;
    -ms-transform: translate(-80px, 0px);
  }

  100% {
    opacity: 1;
    -ms-transform: translate(0px, 0px);
  }
}

/* Loading
-------------------------------------------------------- */

.loading {
  min-height: 400px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.loading .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  margin-left: -25px;
  border: 5px solid #41B6E6;
  border-color: #41B6E6 rgba(65, 182, 230, 0.2) rgba(65, 182, 230, 0.2) rgba(65, 182, 230, 0.2);
  border-radius: 50%;
  animation: spinner 1s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.text-brand-secondary {
  color: #41B6E6;
}

.text-success {
  color: #22C5BE !important;
}

.text-success-action {
  color: #1EACA6;
}

.text-bold {
  font-weight: 700;
}

input[type="radio"]:disabled {
  background: #ccc;
}

/* Partials
-------------------------------------------------------- */

/* Overrides
-------------------------------------------------------- */

.control-label.text-left {
  text-align: left;
}

.control-label small {
  font-weight: normal;
}

.btn {
  transition: all 0.4s ease;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  outline: none !important;
}

.btn.text-left {
  text-align: left;
}

.btn.text-right {
  text-align: right;
}

.btn:active,
.btn.active {
  box-shadow: none;
}

.panel {
  box-shadow: 0 1px 5px 3px rgba(0, 0, 0, 0.05);
  /* Medium devices (desktops, 992px and up) */
}

/* Form input
-------------------------------------------------------- */

textarea {
  resize: vertical;
  min-height: 150px;
  max-height: 300px;
}

.form-control {
  transition: box-shadow 0.4s ease;
  box-shadow: 0 0 0 0 rgba(0, 118, 206, 0.4);
}

.form-control:focus {
  box-shadow: 0 0 0 2px rgba(0, 118, 206, 0.4);
}

input[type="checkbox"],
input[type="radio"] {
  border: 1px solid #EEEEEE;
  position: relative;
  background: #fff;
  clear: none;
  cursor: pointer;
  display: inline-block;
  line-height: 0;
  height: 22px;
  margin: -5.5px 5.5px 0 0;
  outline: none !important;
  padding: 0;
  text-align: center;
  vertical-align: middle;
  width: 22px;
  min-width: 22px;
  -webkit-appearance: none;
  transition: box-shadow 0.4s ease;
  border-radius: 0;
}

input[type="checkbox"]:focus,
input[type="radio"]:focus {
  box-shadow: 0 0 0 2px rgba(0, 118, 206, 0.4);
  border-color: #0076CE;
}

input[type="checkbox"]:checked,
input[type="radio"]:checked {
  border-color: #0076CE;
}

input[type="checkbox"]:checked::before,
input[type="radio"]:checked::before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

header#masthead {
  position: relative;
  width: 100%;
  /** TABLET STYLES **/
  /** LAPTOP STYLES **/
  /** DESKTOP STYLES **/
}

header#masthead .logo {
  position: relative;
  top: 0;
  left: 0;
  float: left;
}

header#masthead .logo svg,
header#masthead .logo img {
  width: 100px;
  height: 29px;
}

header#masthead nav {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background: #444444;
  color: #fff;
  text-align: center;
  display: flex;
  flex-flow: row;
}

header#masthead nav img,
header#masthead nav svg {
  display: block;
  height: 20px;
  margin: 0 auto 7px;
}

header#masthead nav a {
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 11px 0 8px 0;
  line-height: 14px;
  flex-grow: 1;
}

header#masthead nav a:hover {
  background-color: #0076CE;
}

.irs {
  height: 45px;
}

.irs.irs-with-grid {
  height: 65px;
}

.irs-line {
  height: 15px;
  top: 0;
  overflow: hidden;
  border-radius: 15px;
}

.irs-line .irs-line-left,
.irs-line .irs-line-mid,
.irs-line .irs-line-right {
  height: 15px;
  background: #0000000D;
}

.irs-bar {
  height: 15px;
  top: 0;
  background: #0076CE;
}

.irs-shadow {
  display: none;
}

.irs-slider {
  width: 15px;
  height: 15px;
  top: 0;
  border-radius: 15px;
  border: 2px solid #0076CE;
  background: #fff;
  transition: border-color 0.4s ease;
}

.irs-slider.state_hover,
.irs-slider:hover {
  border-color: #00599b;
}

.irs-min,
.irs-max {
  top: -20px;
}

.irs-from,
.irs-to,
.irs-single {
  top: 20px;
}

nav.stage-navigation {
  /* Medium devices (desktops, 992px and up) */
}

nav.stage-navigation .ui-left,
nav.stage-navigation .ui-right {
  height: 18px;
  position: absolute;
}

nav.stage-navigation .ui-right {
  right: 20px;
}

nav.stage-navigation .ui-left {
  left: 20px;
}

.styled-dropdown {
  position: relative;
  height: 45px;
  border: solid 1.5px #fff;
  overflow: hidden;
  color: #22C5BE;
}

.styled-dropdown span.title {
  color: #fff;
}

.styled-dropdown span {
  display: block;
  width: 100%;
  height: 100%;
  padding: 11px 9px;
  font-weight: 200;
  font-size: 14px;
  cursor: pointer;
}

.styled-dropdown span::after {
  content: url("../../images/icons/icon-chev-down-light.png");
  position: absolute;
  right: 9px;
}

.styled-dropdown ul {
  position: relative;
  background: #fff;
  color: #22C5BE;
  margin: 0 -1.5px;
  z-index: 100;
  max-height: 180px;
  display: none;
}

.styled-dropdown ul li {
  padding: 7px 9px;
  font-size: 14px;
  cursor: pointer;
  border: solid white;
  border-width: 0 1.5px 0 1.5px;
  margin: 0;
  color: #22C5BE;
}

.styled-dropdown ul li::before {
  content: none;
}

.styled-dropdown ul li:hover {
  background: #1eafa9;
  color: #fff;
}

.styled-dropdown ul li:last-of-type {
  border-width: 0 1.5px 1.5px 1.5px;
}

.styled-dropdown.open {
  overflow: visible;
}

.styled-dropdown.open ul {
  display: block;
}

.boomi-tooltip {
  display: none;
}

header,
> div section,
> div section .row > div {
  transition: all .25s ease;
}

body.tooltip-active > div section {
  background-color: #00070a !important;
}

body.tooltip-active header,
body.tooltip-active > div section .row > div {
  opacity: 0.1;
}

body.tooltip-active > div section .row > div.progress-col,
body.tooltip-active > div section .row > div.graph-col {
  opacity: 1;
}

body.tooltip-active .resonator {
  position: absolute;
  background-color: #41B6E6;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  box-shadow: 0 0 0 7px rgba(65, 182, 230, 0.3);
}

body.tooltip-active .resonator::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(65, 182, 230, 0.2);
  animation-name: resonate;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

body.tooltip-active ul.progressbar .substep,
body.tooltip-active ul.progressbar .step-indicator,
body.tooltip-active ul.progressbar .step-spacer {
  opacity: 0.2;
}

body.tooltip-active ul.progressbar .active-step .step-indicator {
  position: relative;
  box-shadow: 0 0 0 7px rgba(65, 182, 230, 0.3);
  opacity: 1;
}

body.tooltip-active ul.progressbar .active-step .step-indicator::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(65, 182, 230, 0.2);
  animation-name: resonate;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

body.tooltip-active .boomi-tooltip {
  background: #22C5BE;
  color: #fff;
  position: absolute;
  width: 54vw;
  padding: 10px;
  text-align: left;
  font-weight: 200;
  font-size: 16px;
  transform: translate(-8px, 30px);
  display: flex;
  border-radius: 2px;
  cursor: pointer;
  z-index: 1000;
  transition: opacity 0.25s ease;
}

body.tooltip-active .boomi-tooltip > div {
  display: block;
  width: 80%;
}

body.tooltip-active .boomi-tooltip span {
  background-color: transparent !important;
  height: auto;
  display: inline;
  margin: 0 0 0 0 !important;
}

body.tooltip-active .boomi-tooltip button {
  margin: 0;
  color: #fff;
  opacity: 1;
  font-weight: 200;
  font-size: 16px;
  text-transform: none;
  display: block;
  width: 20%;
  text-align: right;
  padding-right: 10px;
}

body.tooltip-active .boomi-tooltip button:focus,
body.tooltip-active .boomi-tooltip button:active {
  outline: none;
}

body.tooltip-active .boomi-tooltip::before {
  content: "";
  display: block;
  position: absolute;
  left: 10px;
  top: -6px;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  transform: rotate(45deg);
  background-color: #22C5BE;
  z-index: -1;
}

body.tooltip-active .boomi-tooltip.closed {
  opacity: 0;
  pointer-events: none;
}

body.tooltip-active .graph-col .boomi-tooltip {
  transform: translate(10px, -86px);
  width: 90%;
  max-width: 500px;
}

body.tooltip-active .graph-col .boomi-tooltip::before {
  top: auto;
  bottom: -8px;
  left: 61px;
}

@keyframes resonate {
  0% {
    box-shadow: 0 0 0 0 rgba(65, 182, 230, 0.2);
  }

  40% {
    box-shadow: 0 0 0 14px rgba(65, 182, 230, 0.2);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(65, 182, 230, 0.2);
  }
}

/* Overrides
-------------------------------------------------------- */

.control-label.text-left {
  text-align: left;
}

.control-label small {
  font-weight: normal;
}

.btn {
  transition: all 0.4s ease;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  outline: none !important;
}

.btn.text-left {
  text-align: left;
}

.btn.text-right {
  text-align: right;
}

.btn:active,
.btn.active {
  box-shadow: none;
}

.panel {
  box-shadow: 0 1px 5px 3px rgba(0, 0, 0, 0.05);
  /* Medium devices (desktops, 992px and up) */
}

/* Form input
-------------------------------------------------------- */

textarea {
  resize: vertical;
  min-height: 150px;
  max-height: 300px;
}

.form-control {
  transition: box-shadow 0.4s ease;
  box-shadow: 0 0 0 0 rgba(0, 118, 206, 0.4);
}

.form-control:focus {
  box-shadow: 0 0 0 2px rgba(0, 118, 206, 0.4);
}

input[type="checkbox"],
input[type="radio"] {
  border: 1px solid #EEEEEE;
  position: relative;
  background: #fff;
  clear: none;
  cursor: pointer;
  display: inline-block;
  line-height: 0;
  height: 22px;
  margin: -5.5px 5.5px 0 0;
  outline: none !important;
  padding: 0;
  text-align: center;
  vertical-align: middle;
  width: 22px;
  min-width: 22px;
  -webkit-appearance: none;
  transition: box-shadow 0.4s ease;
  border-radius: 0;
}

input[type="checkbox"]:focus,
input[type="radio"]:focus {
  box-shadow: 0 0 0 2px rgba(0, 118, 206, 0.4);
  border-color: #0076CE;
}

input[type="checkbox"]:checked,
input[type="radio"]:checked {
  border-color: #0076CE;
}

input[type="checkbox"]:checked::before,
input[type="radio"]:checked::before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.irs {
  height: 45px;
}

.irs.irs-with-grid {
  height: 65px;
}

.irs-line {
  height: 15px;
  top: 0;
  overflow: hidden;
  border-radius: 15px;
}

.irs-line .irs-line-left,
.irs-line .irs-line-mid,
.irs-line .irs-line-right {
  height: 15px;
  background: #0000000D;
}

.irs-bar {
  height: 15px;
  top: 0;
  background: #0076CE;
}

.irs-shadow {
  display: none;
}

.irs-slider {
  width: 15px;
  height: 15px;
  top: 0;
  border-radius: 15px;
  border: 2px solid #0076CE;
  background: #fff;
  transition: border-color 0.4s ease;
}

.irs-slider.state_hover,
.irs-slider:hover {
  border-color: #00599b;
}

.irs-min,
.irs-max {
  top: -20px;
}

.irs-from,
.irs-to,
.irs-single {
  top: 20px;
}

nav.form-navigation {
  margin-bottom: 40px;
  /* Content
  -------------------------------------------------------- */
  /* Navigation dots
  -------------------------------------------------------- */
}

nav.form-navigation .content {
  margin-top: 30px;
  margin-bottom: 40px;
}

nav.form-navigation ul.dots {
  padding: 0;
  margin: 0;
  display: block;
  text-align: justify;
  height: 40px;
}

nav.form-navigation ul.dots::after {
  content: '';
  width: 100%;
  display: inline-block;
  font-size: 0;
  line-height: 0;
}

nav.form-navigation ul.dots li {
  padding: 0;
  margin: 0;
  position: relative;
  display: inline-block;
  line-height: 40px;
}

nav.form-navigation ul.dots li a,
nav.form-navigation ul.dots li span {
  display: inline-block;
  width: 10px;
  height: 10px;
  text-indent: -999px;
  overflow: hidden;
  border-radius: 50%;
  background: #0076CE;
}

nav.form-navigation ul.dots li a.disabled,
nav.form-navigation ul.dots li span.disabled {
  cursor: auto;
  background: #EEEEEE;
  pointer-events: none;
}

nav.form-navigation ul.dots li a.active,
nav.form-navigation ul.dots li span.active {
  transform: scale(2);
}

nav.form-navigation ul.dots li.progress-item {
  margin: 0;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  right: 10px;
  height: 4px;
  background: #EEEEEE;
  pointer-events: none;
}

nav.form-navigation ul.dots li.progress-item .fill {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: #0076CE;
}

nav.stage-navigation {
  /* Medium devices (desktops, 992px and up) */
}

nav.stage-navigation .ui-left,
nav.stage-navigation .ui-right {
  height: 18px;
  position: absolute;
}

nav.stage-navigation .ui-right {
  right: 20px;
}

nav.stage-navigation .ui-left {
  left: 20px;
}

.styled-dropdown {
  position: relative;
  height: 45px;
  border: solid 1.5px #fff;
  overflow: hidden;
  color: #22C5BE;
}

.styled-dropdown span.title {
  color: #fff;
}

.styled-dropdown span {
  display: block;
  width: 100%;
  height: 100%;
  padding: 11px 9px;
  font-weight: 200;
  font-size: 14px;
  cursor: pointer;
}

.styled-dropdown span::after {
  content: url("../../images/icons/icon-chev-down-light.png");
  position: absolute;
  right: 9px;
}

.styled-dropdown ul {
  position: relative;
  background: #fff;
  color: #22C5BE;
  margin: 0 -1.5px;
  z-index: 100;
  max-height: 180px;
  display: none;
}

.styled-dropdown ul li {
  padding: 7px 9px;
  font-size: 14px;
  cursor: pointer;
  border: solid white;
  border-width: 0 1.5px 0 1.5px;
  margin: 0;
  color: #22C5BE;
}

.styled-dropdown ul li::before {
  content: none;
}

.styled-dropdown ul li:hover {
  background: #1eafa9;
  color: #fff;
}

.styled-dropdown ul li:last-of-type {
  border-width: 0 1.5px 1.5px 1.5px;
}

.styled-dropdown.open {
  overflow: visible;
}

.styled-dropdown.open ul {
  display: block;
}

.boomi-tooltip {
  display: none;
}

header,
> div section,
> div section .row > div {
  transition: all .25s ease;
}

body.tooltip-active > div section {
  background-color: #00070a !important;
}

body.tooltip-active header,
body.tooltip-active > div section .row > div {
  opacity: 0.1;
}

body.tooltip-active > div section .row > div.progress-col,
body.tooltip-active > div section .row > div.graph-col {
  opacity: 1;
}

body.tooltip-active .resonator {
  position: absolute;
  background-color: #41B6E6;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  box-shadow: 0 0 0 7px rgba(65, 182, 230, 0.3);
}

body.tooltip-active .resonator::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(65, 182, 230, 0.2);
  animation-name: resonate;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

body.tooltip-active ul.progressbar .substep,
body.tooltip-active ul.progressbar .step-indicator,
body.tooltip-active ul.progressbar .step-spacer {
  opacity: 0.2;
}

body.tooltip-active ul.progressbar .active-step .step-indicator {
  position: relative;
  box-shadow: 0 0 0 7px rgba(65, 182, 230, 0.3);
  opacity: 1;
}

body.tooltip-active ul.progressbar .active-step .step-indicator::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(65, 182, 230, 0.2);
  animation-name: resonate;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

body.tooltip-active .boomi-tooltip {
  background: #22C5BE;
  color: #fff;
  position: absolute;
  width: 54vw;
  padding: 10px;
  text-align: left;
  font-weight: 200;
  font-size: 16px;
  transform: translate(-8px, 30px);
  display: flex;
  border-radius: 2px;
  cursor: pointer;
  z-index: 1000;
  transition: opacity 0.25s ease;
}

body.tooltip-active .boomi-tooltip > div {
  display: block;
  width: 80%;
}

body.tooltip-active .boomi-tooltip span {
  background-color: transparent !important;
  height: auto;
  display: inline;
  margin: 0 0 0 0 !important;
}

body.tooltip-active .boomi-tooltip button {
  margin: 0;
  color: #fff;
  opacity: 1;
  font-weight: 200;
  font-size: 16px;
  text-transform: none;
  display: block;
  width: 20%;
  text-align: right;
  padding-right: 10px;
}

body.tooltip-active .boomi-tooltip button:focus,
body.tooltip-active .boomi-tooltip button:active {
  outline: none;
}

body.tooltip-active .boomi-tooltip::before {
  content: "";
  display: block;
  position: absolute;
  left: 10px;
  top: -6px;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  transform: rotate(45deg);
  background-color: #22C5BE;
  z-index: -1;
}

body.tooltip-active .boomi-tooltip.closed {
  opacity: 0;
  pointer-events: none;
}

body.tooltip-active .graph-col .boomi-tooltip {
  transform: translate(10px, -86px);
  width: 90%;
  max-width: 500px;
}

body.tooltip-active .graph-col .boomi-tooltip::before {
  top: auto;
  bottom: -8px;
  left: 61px;
}

@keyframes resonate {
  0% {
    box-shadow: 0 0 0 0 rgba(65, 182, 230, 0.2);
  }

  40% {
    box-shadow: 0 0 0 14px rgba(65, 182, 230, 0.2);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(65, 182, 230, 0.2);
  }
}

.sort-code .form-control {
  display: inline-block;
  width: 50px;
  text-align: center;
}

.sort-code span {
  color: #EEEEEE;
  margin: 0 10px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Views
-------------------------------------------------------- */

section#video {
  padding: 0;
}

section#video .splash-image {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
}

section#video .dell-logo {
  width: 100px;
  z-index: 1;
  position: absolute;
  top: 30px;
  left: 20px;
}

section#video .intel-logo {
  width: 100px;
  z-index: 1;
  position: absolute;
  top: 32px;
  right: 30px;
}

section#video .dell-logo,
section#video .intel-logo {
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

section#video video::-webkit-media-controls-fullscreen-button {
  display: none;
}

section#video .heading {
  font-size: 16px;
  line-height: 1.3em;
  position: absolute;
  top: 32%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
}

section#video .arrow-right {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 18px solid #fff;
  position: relative;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  margin-left: 3px;
}

section#video .octagon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  transform: rotate(45deg);
  border: 3px solid white;
  transition: border 200ms ease-out, background-color 200ms ease-out;
}

section#video .octagon:before {
  position: absolute;
  /* There needs to be a negative value here to cancel
		 * out the width of the border. It's currently -3px,
		 * but if the border were 5px, then it'd be -5px.
		 */
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  transform: rotate(45deg);
  content: '';
  border: inherit;
}

section#video .octagonWrap {
  width: 75px;
  height: 75px;
  float: left;
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  cursor: pointer;
}

section#video .octagonWrap:hover .octagon {
  background-color: #0076CE;
  border-color: #0076CE;
}

section#video #video-endpoint .skip-button {
  right: 0;
  left: auto;
  top: 60px;
  bottom: 10px;
  width: 90px;
  height: 90px;
  z-index: 100;
}

section#video #video-endpoint .octagon {
  border: none;
}

section#video #video-endpoint img {
  width: 34px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  top: 15px;
}

section#video #video-endpoint span {
  text-decoration: none;
  color: #fff;
  position: absolute;
  top: 55px;
  left: 50%;
  transform: translateX(-50%);
  line-height: 1em;
}

section#video.splash-screen {
  width: 100vw;
  height: 100vh;
  background: grey;
}

section#video .video-skin {
  display: block;
  vertical-align: top;
  box-sizing: border-box;
  color: #fff;
  position: relative;
  padding: 0;
  font-size: 10px;
  line-height: 1;
  font-weight: 400;
  font-style: normal;
  font-family: Arial,Helvetica,sans-serif;
  word-break: initial;
  height: calc(100vw / 1.7777);
  width: calc(100vh * 1.7777);
  max-width: 100vw;
  max-height: 100vh;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

section#stages {
  background-image: url(/images/backgrounds/Web_background@2x.jpg);
}

section#cyber-stages {
  background-image: url(/images/backgrounds/Stage_0_Welcome_White.jpg);
}

section#stages,
section#cyber-stages {
  font-family: "Roboto", sans-serif;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  padding-top: 0;
  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: space-between;
}

section#stages .stages-options,
section#cyber-stages .stages-options {
  margin: 0 auto;
}

section#stages .stages-options .title-container,
section#cyber-stages .stages-options .title-container {
  margin-bottom: 30px;
}

section#stages .stages-options .title-container h1,
section#cyber-stages .stages-options .title-container h1 {
  color: #444444;
  font-size: 24px;
  letter-spacing: 7px;
  margin: 0;
}

section#stages .stages-options .title-container p,
section#cyber-stages .stages-options .title-container p {
  color: #444444;
  font-size: 12px;
  font-weight: 500;
}

section#stages .stages-options .title-container.cyber-recovery h1,
section#stages .stages-options .title-container.cyber-recovery p,
section#cyber-stages .stages-options .title-container.cyber-recovery h1,
section#cyber-stages .stages-options .title-container.cyber-recovery p {
  color: #444444;
}

@keyframes octo-frame {
  0% {
    stroke: transparent;
    fill: transparent;
  }

  50% {
    stroke: transparent;
    fill: transparent;
  }

  100% {
    stroke: transparent;
    fill: transparent;
  }
}

@keyframes octo-play {
  0% {
    stroke: transparent;
    fill: transparent;
  }

  50% {
    stroke: transparent;
    fill: transparent;
  }

  100% {
    stroke: #ffffff;
    fill: #ffffff;
  }
}

@keyframes octo {
  0% {
    stroke: transparent;
    fill: transparent;
  }

  100% {
    stroke: transparent;
    fill: #0069b5;
  }
}

section#stages .stages-options .stages-container,
section#cyber-stages .stages-options .stages-container {
  padding: 0;
  justify-content: space-between;
  margin: 0 auto;
}

section#stages .stages-options .stages-container .stages-pairs,
section#cyber-stages .stages-options .stages-container .stages-pairs {
  justify-content: space-between;
}

section#stages .stages-options .stages-container .stages-pairs .stage-ind,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 47%;
}

section#stages .stages-options .stages-container .stages-pairs .stage-ind:hover .stage-info p,
section#stages .stages-options .stages-container .stages-pairs .stage-ind:hover .stage-info h2,
section#stages .stages-options .stages-container .stages-pairs .stage-ind:hover .stage-info a,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind:hover .stage-info p,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind:hover .stage-info h2,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind:hover .stage-info a {
  color: #fff;
}

section#stages .stages-options .stages-container .stages-pairs .stage-ind:hover .stage-info svg path,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind:hover .stage-info svg path {
  fill: #fff !important;
}

section#stages .stages-options .stages-container .stages-pairs .stage-ind:hover .video-section,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind:hover .video-section {
  cursor: pointer;
}

section#stages .stages-options .stages-container .stages-pairs .stage-ind:hover .video-section .gif-preview,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind:hover .video-section .gif-preview {
  opacity: 1;
}

section#stages .stages-options .stages-container .stages-pairs .stage-ind:hover .video-section .image-preview,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind:hover .video-section .image-preview {
  opacity: 0;
}

section#stages .stages-options .stages-container .stages-pairs .stage-ind:hover .video-section .octagon,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind:hover .video-section .octagon {
  opacity: 1;
  border: 0;
}

section#stages .stages-options .stages-container .stages-pairs .stage-ind:hover .video-section .octagon .octagon-poly,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind:hover .video-section .octagon .octagon-poly {
  animation: octo;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}

section#stages .stages-options .stages-container .stages-pairs .stage-ind:hover .video-section .octagon .octagon-frame,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind:hover .video-section .octagon .octagon-frame {
  animation: octo-frame;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}

section#stages .stages-options .stages-container .stages-pairs .stage-ind:hover .video-section .octagon .octagon-play,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind:hover .video-section .octagon .octagon-play {
  animation: octo-play;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}

section#stages .stages-options .stages-container .stages-pairs .stage-ind.awareness:hover .stage-info,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind.awareness:hover .stage-info {
  background-color: #41b6e6;
}

section#stages .stages-options .stages-container .stages-pairs .stage-ind.consideration:hover .stage-info,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind.consideration:hover .stage-info {
  background-color: #f2af00;
}

section#stages .stages-options .stages-container .stages-pairs .stage-ind.conversation:hover .stage-info,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind.conversation:hover .stage-info {
  background-color: #6ea204;
}

section#stages .stages-options .stages-container .stages-pairs .stage-ind.after-sales:hover .stage-info,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind.after-sales:hover .stage-info {
  background-color: #ee6411;
}

section#stages .stages-options .stages-container .stages-pairs .stage-ind .video-section,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind .video-section {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

section#stages .stages-options .stages-container .stages-pairs .stage-ind .video-section .gif-preview,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind .video-section .gif-preview {
  transition: opacity 0.2s ease;
  opacity: 0;
  object-fit: cover;
  object-position: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

section#stages .stages-options .stages-container .stages-pairs .stage-ind .video-section .image-preview,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind .video-section .image-preview {
  transition: opacity 0.2s ease;
  opacity: 1;
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
}

section#stages .stages-options .stages-container .stages-pairs .stage-ind .video-section .octagon,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind .video-section .octagon {
  width: 65px;
  height: 65px;
  display: block;
  position: absolute;
  background-image: url("../../images/svg/octagon.svg");
  transition: opacity 0.25s ease-in-out;
  opacity: 0;
  border: 0 !important;
}

section#stages .stages-options .stages-container .stages-pairs .stage-ind .video-section .octagon .octagon-poly,
section#stages .stages-options .stages-container .stages-pairs .stage-ind .video-section .octagon .octagon-frame,
section#stages .stages-options .stages-container .stages-pairs .stage-ind .video-section .octagon .octagon-play,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind .video-section .octagon .octagon-poly,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind .video-section .octagon .octagon-frame,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind .video-section .octagon .octagon-play {
  /*stroke: transparent;
                fill: transparent;*/
}

section#stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info {
  transition: background-color 0.2s ease;
  background-color: #f3f3f3;
  text-align: center;
  padding: 12px 0;
  cursor: pointer;
}

section#stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info p,
section#stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info h2,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info p,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info h2 {
  color: #444444;
  margin-bottom: 5px;
  margin-top: 5px;
  transition: color 0.2s ease;
}

section#stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info p,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info p {
  font-size: 12px;
}

section#stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info h2,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info h2 {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

section#stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info .stage-number,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info .stage-number {
  font-weight: 500;
}

section#stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info .preview-link,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info .preview-link {
  color: #0076ce;
  font-size: 14px;
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
}

section#stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info .preview-link svg path,
section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info .preview-link svg path {
  fill: #0076ce;
}

section#stages .stages-options .sub-boxes-container,
section#cyber-stages .stages-options .sub-boxes-container {
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0 auto;
}

section#stages .stages-options .sub-boxes-container .sub-box,
section#cyber-stages .stages-options .sub-boxes-container .sub-box {
  background-color: #0076ce;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 20px;
  padding: 21px 0;
  transition: background-color 0.2s ease;
}

section#stages .stages-options .sub-boxes-container .sub-box.cyber-recovery,
section#cyber-stages .stages-options .sub-boxes-container .sub-box.cyber-recovery {
  background-color: #0076ce;
}

section#stages .stages-options .sub-boxes-container .sub-box.cyber-recovery:hover,
section#cyber-stages .stages-options .sub-boxes-container .sub-box.cyber-recovery:hover {
  background-color: #0069b5;
}

section#stages .stages-options .sub-boxes-container .sub-box:hover,
section#cyber-stages .stages-options .sub-boxes-container .sub-box:hover {
  background-color: #0069b5;
}

section#stages .stages-options .sub-boxes-container .sub-box .description,
section#cyber-stages .stages-options .sub-boxes-container .sub-box .description {
  font-size: 12px;
  color: #fff;
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 500;
}

section#stages .stages-options .sub-boxes-container .sub-box .title,
section#cyber-stages .stages-options .sub-boxes-container .sub-box .title {
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: #fff;
  margin: 10px auto 15px;
  width: 240px;
  text-align: center;
}

section#stages .stages-options .sub-boxes-container .sub-box .sub-box-links,
section#cyber-stages .stages-options .sub-boxes-container .sub-box .sub-box-links {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: center;
}

section#stages .stages-options .sub-boxes-container .sub-box .sub-box-links a,
section#cyber-stages .stages-options .sub-boxes-container .sub-box .sub-box-links a {
  color: #fff;
  text-decoration: none;
  font-weight: normal;
  font-size: 10px;
  margin: 0 5px;
}

section#stages .stages-options .sub-boxes-container .sub-box .sub-box-links img,
section#cyber-stages .stages-options .sub-boxes-container .sub-box .sub-box-links img {
  width: 16px;
}

#assets,
#cyber-assets {
  background-color: #444444;
  background-position: center;
  background-size: cover;
  padding-top: 0;
  min-height: 100vh;
  flex-flow: column;
  justify-content: space-between;
  /** TABLET STYLES **/
  /** LAPTOP STYLES **/
  /** DESKTOP STYLES **/
}

#assets h1,
#cyber-assets h1 {
  padding: 0 2.5%;
  letter-spacing: 7.2px;
  margin-top: 5px;
}

#assets h1.dark,
#cyber-assets h1.dark {
  color: #444444;
}

#assets .asset-page-number,
#cyber-assets .asset-page-number {
  display: flex;
  margin: 0 auto;
  background-image: url(/images/hexagon.svg);
  background-size: contain;
  background-repeat: no-repeat;
  color: #fff;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 10px;
  font-weight: 900;
  align-content: center;
  justify-content: center;
  padding-left: 1px;
}

#assets .asset-page-number-1,
#cyber-assets .asset-page-number-1 {
  background-image: url(/images/hexagon_1.svg);
}

#assets .asset-page-number-2,
#cyber-assets .asset-page-number-2 {
  background-image: url(/images/hexagon_2.svg);
}

#assets .asset-page-number-3,
#cyber-assets .asset-page-number-3 {
  background-image: url(/images/hexagon_3.svg);
}

#assets .asset-page-number-4,
#cyber-assets .asset-page-number-4 {
  background-image: url(/images/hexagon_4.svg);
}

#assets .asset-page-number-5,
#cyber-assets .asset-page-number-5 {
  background-image: url(/images/hexagon_5.svg);
}

#assets .powerstore,
#assets .cyber-recovery,
#cyber-assets .powerstore,
#cyber-assets .cyber-recovery {
  display: block;
  font-size: 12px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  padding-top: 5px;
  font-weight: 500;
}

#assets .asset-tile-wrapper,
#cyber-assets .asset-tile-wrapper {
  padding: 10px;
}

#assets .asset-tile,
#cyber-assets .asset-tile {
  display: block;
  background-color: rgba(255, 255, 255, 0.9);
  height: 100%;
  transition: background-color 0.25s;
}

#assets .asset-tile:hover,
#cyber-assets .asset-tile:hover {
  background-color: white;
}

#assets .asset-tile.is-tip,
#cyber-assets .asset-tile.is-tip {
  background-color: rgba(65, 182, 230, 0.9);
  background-repeat: no-repeat;
  background-position: bottom 10px right 5px;
  background-size: 40px;
  padding: 27px 15px;
  height: auto;
}

#assets .asset-tile.is-tip .tip-title,
#cyber-assets .asset-tile.is-tip .tip-title {
  font-size: 12px;
  line-height: 29px;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
}

#assets .asset-tile.is-tip p,
#cyber-assets .asset-tile.is-tip p {
  margin: 10px 0 0 0;
  font-size: 20px;
  line-height: 26px;
}

#assets .asset-tile.is-tip .source,
#cyber-assets .asset-tile.is-tip .source {
  color: #fff;
  font-size: 11px;
  font-weight: 500;
  line-height: 15px;
  text-decoration: none;
  display: block;
  margin-bottom: 5px;
  padding: 20px 0 0 0;
}

#assets .asset-tile.is-tip .source:hover,
#cyber-assets .asset-tile.is-tip .source:hover {
  text-decoration: underline;
}

#assets .asset-tile a,
#cyber-assets .asset-tile a {
  display: flex;
  flex-direction: column;
  color: #444444;
  text-decoration: none;
  padding: 17px 10px;
  height: 100%;
}

#assets .asset-tile .asset-type,
#cyber-assets .asset-tile .asset-type {
  display: block;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  letter-spacing: 0.6px;
  margin-bottom: 5px;
}

#assets .asset-tile .asset-name,
#cyber-assets .asset-tile .asset-name {
  display: block;
  font-size: 12px;
  line-height: 20px;
  font-weight: 900;
  letter-spacing: 1.4px;
  flex-grow: 1;
}

#assets .asset-tile .download,
#cyber-assets .asset-tile .download {
  font-size: 14px;
  line-height: 14px;
  color: #0076CE;
  font-weight: 400;
  padding-top: 20px;
}

#assets .asset-tile .download img,
#assets .asset-tile .download svg,
#cyber-assets .asset-tile .download img,
#cyber-assets .asset-tile .download svg {
  width: 15px;
  margin-left: 8px;
  vertical-align: baseline;
}

#assets .asset-tile .icon,
#cyber-assets .asset-tile .icon {
  height: 33px;
  margin-bottom: 18px;
  align-self: flex-start;
}

#assets .asset-tile .icon.banner,
#cyber-assets .asset-tile .icon.banner {
  padding-bottom: 5px;
}

#assets .asset-tile .icon.email,
#cyber-assets .asset-tile .icon.email {
  padding-bottom: 6px;
}

#assets .asset-tile .icon.player,
#cyber-assets .asset-tile .icon.player {
  padding-bottom: 3px;
}

#assets .next-stage,
#cyber-assets .next-stage {
  padding: 10px;
}

#assets .next-stage .box,
#cyber-assets .next-stage .box {
  background: rgba(68, 68, 68, 0.9);
  color: #fff;
  margin: 25px 0 80px;
  position: relative;
}

#assets .next-stage .box figure,
#cyber-assets .next-stage .box figure {
  background-size: cover;
  background-position: center;
  width: 100%;
  padding-bottom: 56%;
  margin-bottom: 0;
}

#assets .next-stage .box main,
#cyber-assets .next-stage .box main {
  padding: 27px 20px;
}

#assets .next-stage .box span,
#cyber-assets .next-stage .box span {
  display: block;
}

#assets .next-stage .box .proceed,
#cyber-assets .next-stage .box .proceed {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.6px;
  font-weight: 500;
}

#assets .next-stage .box .stage-name,
#cyber-assets .next-stage .box .stage-name {
  margin-top: 15px;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 5.4px;
  font-weight: 900;
}

#assets .sources a,
#cyber-assets .sources a {
  color: #fff;
  font-size: 11px;
  font-weight: 500;
  line-height: 15px;
  text-decoration: none;
  display: block;
  margin-bottom: 5px;
}

#assets .sources a:hover,
#cyber-assets .sources a:hover {
  text-decoration: underline;
}

section#quick-ref {
  background-image: url("../../images/layout/bkg_generic_light@2x.jpg");
}

section#cyber-quick-ref {
  background-image: url("../../images/backgrounds/Stage_6_Closing.jpg");
}

section#cyber-quick-ref .asset-page-number {
  display: flex;
  margin: 0 auto;
  background-image: url(/images/hexagon_6.svg);
  background-size: contain;
  background-repeat: no-repeat;
  color: #fff;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 10px;
  font-weight: 900;
  align-content: center;
  justify-content: center;
  padding-left: 1px;
}

section#quick-ref,
section#cyber-quick-ref {
  background-color: #f3f3f3;
  background-position: center center;
  background-size: cover;
  min-height: 100vh;
  font-family: roboto, sans-serif;
  flex-flow: column;
  justify-content: space-between;
  padding-top: 0;
}

section#quick-ref .quick-ref-container .quick-ref-options-container,
section#cyber-quick-ref .quick-ref-container .quick-ref-options-container {
  margin: 0 auto;
}

section#quick-ref .quick-ref-container .quick-ref-options-container .title-container,
section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .title-container {
  width: 80%;
  margin: 0 auto 30px;
}

section#quick-ref .quick-ref-container .quick-ref-options-container .title-container h1,
section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .title-container h1 {
  color: #444;
  font-size: 24px;
  letter-spacing: 7px;
  text-transform: uppercase;
  text-align: center;
  font-family: roboto, sans-serif;
  font-weight: 900;
  margin: 0 0 30px 0;
}

section#quick-ref .quick-ref-container .quick-ref-options-container .title-container p,
section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .title-container p {
  color: #444;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: roboto, sans-serif;
  margin-bottom: 10px;
}

section#quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options,
section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options {
  margin: 0 auto;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: 1fr 1fr 1fr;
  gap: 20px 20px;
  grid-template-areas: ". ." ". ." ". .";
}

section#quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind,
section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-decoration: none;
}

section#quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .video-section,
section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .video-section {
  height: 88px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

section#quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .video-section .gif-preview,
section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .video-section .gif-preview {
  transition: opacity 0.2s ease;
  opacity: 0;
  object-fit: cover;
  object-position: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

section#quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .video-section .image-preview,
section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .video-section .image-preview {
  transition: opacity 0.2s ease;
  opacity: 1;
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
}

section#quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .video-section .octagon,
section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .video-section .octagon {
  width: 65px;
  height: 65px;
  display: block;
  position: absolute;
  background-image: url("../../images/svg/octagon.svg");
}

section#quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .video-section .octagon .octagon-poly,
section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .video-section .octagon .octagon-poly {
  stroke: transparent;
  fill: transparent;
}

section#quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .quick-ref-info,
section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .quick-ref-info {
  transition: background-color 0.2s ease;
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  height: 67%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

section#quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .quick-ref-info p.preview-link,
section#quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .quick-ref-info h2.quick-ref-title,
section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .quick-ref-info p.preview-link,
section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .quick-ref-info h2.quick-ref-title {
  color: #444;
  padding-bottom: 24px;
  margin: 0;
  padding-right: 5px;
  padding-left: 5px;
  transition: color 0.2s ease;
  font-family: roboto, sans-serif;
}

section#quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .quick-ref-info p.preview-link,
section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .quick-ref-info p.preview-link {
  font-size: 12px;
  color: #0076ce;
  font-weight: 400;
  line-height: 1;
}

section#quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .quick-ref-info p.preview-link .launch-icon,
section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .quick-ref-info p.preview-link .launch-icon {
  width: 20px;
  margin-left: 10px;
  vertical-align: baseline;
}

section#quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .quick-ref-info p.preview-link.launch-site .launch-icon,
section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .quick-ref-info p.preview-link.launch-site .launch-icon {
  transform: translateY(5px);
}

section#quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .quick-ref-info h2.quick-ref-title,
section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .quick-ref-info h2.quick-ref-title {
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  padding-top: 24px;
}

section#quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind:hover .quick-ref-info,
section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind:hover .quick-ref-info {
  background-color: white;
}

section#four04 {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  width: 100%;
  padding-top: 0;
  flex-flow: column;
  justify-content: center;
}

section#four04 #masthead {
  position: absolute;
  top: 0;
  left: 0;
}

section#four04 h1 {
  letter-spacing: 12.6px;
}

section#portal {
  padding-top: 0;
  background-image: url(../../images/backgrounds/Generic_BGD_3.jpg);
  background-size: cover;
  background-position: unset;
}

section#portal .container {
  padding-top: 0px;
}

section#portal .container .title-container .portal-header-eyebrow {
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 3px;
}

section#portal .container .title-container h1 {
  margin-bottom: 18px;
  font-size: 24px;
  line-height: 28px;
  margin-top: 5px;
  padding: 0;
}

section#portal .container .title-container .portal-header-intro {
  max-width: 1000px;
  margin: 0 auto;
}

section#portal .portal-options {
  margin: 0 auto;
  max-width: 92%;
  margin-top: 53px;
}

section#portal .portal-options .portal-pairs {
  justify-content: space-between;
  flex-direction: column;
}

section#portal .portal-options .portal-pairs .portal-ind {
  display: flex;
  flex-direction: column;
  margin-bottom: 31px;
  width: 100%;
}

section#portal .portal-options .portal-pairs .portal-ind:hover .portal-info {
  background-color: #0076CE;
}

section#portal .portal-options .portal-pairs .portal-ind:hover .portal-info p,
section#portal .portal-options .portal-pairs .portal-ind:hover .portal-info h2,
section#portal .portal-options .portal-pairs .portal-ind:hover .portal-info a {
  color: #fff;
}

section#portal .portal-options .portal-pairs .portal-ind:hover .portal-info svg path {
  fill: #fff !important;
}

section#portal .portal-options .portal-pairs .portal-ind:hover .video-section {
  cursor: pointer;
}

section#portal .portal-options .portal-pairs .portal-ind:hover .video-section .gif-preview {
  opacity: 1;
}

section#portal .portal-options .portal-pairs .portal-ind:hover .video-section .image-preview {
  opacity: 0;
}

section#portal .portal-options .portal-pairs .portal-ind:hover .video-section .octagon {
  opacity: 1;
  border: 0;
}

section#portal .portal-options .portal-pairs .portal-ind:hover .video-section .octagon .octagon-poly {
  animation: octo;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}

section#portal .portal-options .portal-pairs .portal-ind:hover .video-section .octagon .octagon-frame {
  animation: octo-frame;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}

section#portal .portal-options .portal-pairs .portal-ind:hover .video-section .octagon .octagon-play {
  animation: octo-play;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
}

section#portal .portal-options .portal-pairs .portal-ind .video-section {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

section#portal .portal-options .portal-pairs .portal-ind .video-section .gif-preview {
  transition: opacity 0.2s ease;
  opacity: 0;
  object-fit: cover;
  object-position: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

section#portal .portal-options .portal-pairs .portal-ind .video-section .image-preview {
  transition: opacity 0.2s ease;
  opacity: 1;
  object-fit: cover;
  object-position: center;
  height: 100%;
  width: 100%;
}

section#portal .portal-options .portal-pairs .portal-ind .video-section .octagon {
  width: 65px;
  height: 65px;
  display: block;
  position: absolute;
  background-image: url("../../images/svg/octagon.svg");
  transition: opacity 0.25s ease-in-out;
  opacity: 0;
  border: 0 !important;
}

section#portal .portal-options .portal-pairs .portal-ind .video-section .octagon .octagon-poly,
section#portal .portal-options .portal-pairs .portal-ind .video-section .octagon .octagon-frame,
section#portal .portal-options .portal-pairs .portal-ind .video-section .octagon .octagon-play {
  /*stroke: transparent;
                fill: transparent;*/
}

section#portal .portal-options .portal-pairs .portal-ind:first-child {
  margin-right: 0px;
}

section#portal .portal-options .portal-pairs .portal-ind .video-section {
  background-color: #0076CE;
}

section#portal .portal-options .portal-pairs .portal-ind .portal-info {
  padding: 42px 0;
  background-color: #fff;
  text-align: center;
}

section#portal .portal-options .portal-pairs .portal-ind .portal-info p,
section#portal .portal-options .portal-pairs .portal-ind .portal-info h2 {
  color: #444;
  margin-bottom: 13px;
  margin-top: 0px;
}

section#portal .portal-options .portal-pairs .portal-ind .portal-info .portal-number {
  font-size: 14px;
}

section#portal .portal-options .portal-pairs .portal-ind .portal-info h2 {
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

section#portal .portal-options .portal-pairs .portal-ind .portal-info .preview-link {
  color: #0076CE;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}

section#portal .portal-options .portal-pairs .portal-ind .portal-info .preview-link .launch-icon {
  display: inline-flex;
  margin-left: 10px;
}

section#cyber-intro {
  padding: 0;
}

section#cyber-intro .splash-image {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
}

section#cyber-intro .dell-logo {
  width: 100px;
  z-index: 1;
  position: absolute;
  top: 30px;
  left: 20px;
}

section#cyber-intro .vmware-logo {
  width: 57px;
  z-index: 1;
  position: absolute;
  top: 41px;
  right: 88px;
}

section#cyber-intro .intel-logo {
  width: 58px;
  z-index: 1;
  position: absolute;
  top: 39px;
  right: 21px;
}

section#cyber-intro .dell-logo,
section#cyber-intro .intel-logo,
section#cyber-intro .vmware-logo {
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

section#cyber-intro video::-webkit-media-controls-fullscreen-button {
  display: none;
}

section#cyber-intro .heading {
  font-size: 24px;
  line-height: 1.3em;
  position: absolute;
  top: 52%;
  left: 50%;
  width: 90%;
  text-align: center;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
}

section#cyber-intro .arrow-right {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 18px solid #fff;
  position: relative;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  margin-left: 3px;
}

section#cyber-intro .octagon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  transform: rotate(45deg);
  border: 3px solid white;
  transition: border 200ms ease-out, background-color 200ms ease-out;
}

section#cyber-intro .octagon:before {
  position: absolute;
  /* There needs to be a negative value here to cancel
		 * out the width of the border. It's currently -3px,
		 * but if the border were 5px, then it'd be -5px.
		 */
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  transform: rotate(45deg);
  content: "";
  border: inherit;
}

section#cyber-intro .octagonWrap {
  width: 70px;
  height: 70px;
  float: left;
  position: absolute;
  top: 39%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  cursor: pointer;
}

section#cyber-intro .octagonWrap:hover .octagon {
  background-color: #006bbb;
  border-color: #006bbb;
}

section#cyber-intro #video-endpoint .skip-button {
  right: 0;
  left: auto;
  top: 60px;
  bottom: 10px;
  width: 90px;
  height: 90px;
  z-index: 100;
}

section#cyber-intro #video-endpoint .octagon {
  border: none;
}

section#cyber-intro #video-endpoint img {
  width: 34px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  top: 15px;
}

section#cyber-intro #video-endpoint span {
  text-decoration: none;
  color: #fff;
  position: absolute;
  top: 55px;
  left: 50%;
  transform: translateX(-50%);
  line-height: 1em;
}

section#cyber-intro.splash-screen {
  width: 100vw;
  height: 100vh;
  background: grey;
}

section#cyber-intro .video-skin {
  display: block;
  vertical-align: top;
  box-sizing: border-box;
  color: #fff;
  position: relative;
  padding: 0;
  font-size: 10px;
  line-height: 1;
  font-weight: 400;
  font-style: normal;
  font-family: Arial, Helvetica, sans-serif;
  word-break: initial;
  height: calc(100vw / 1.7777);
  width: calc(100vh * 1.7777);
  max-width: 100vw;
  max-height: 100vh;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Components
-------------------------------------------------------- */

#footer,
#cyber-footer {
  background: transparent;
  width: 100%;
}

#footer .footer-logo-container,
#cyber-footer .footer-logo-container {
  width: 100%;
  display: flex;
  padding-top: 31px;
  justify-content: flex-end;
}

#footer .footer-logo-container .intel-logo-footer,
#cyber-footer .footer-logo-container .intel-logo-footer {
  width: 116px;
}

#footer .footer-copyright,
#cyber-footer .footer-copyright {
  width: 100%;
  text-align: center;
  padding-top: 31px;
  padding-bottom: 75px;
}

#footer .footer-copyright p,
#cyber-footer .footer-copyright p {
  font-size: 14px;
  color: #444;
}

#footer.white .footer-copyright p,
#cyber-footer.white .footer-copyright p {
  color: #fff;
}

#cyber-footer .footer-logo-container {
  justify-content: center;
}

#cyber-footer .footer-logo-container img {
  margin: 0 0px;
  height: 14px;
}

/* slide in from the right */

@keyframes animFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes animFadeUpIn {
  from {
    transform: translateY(50px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  -webkit-animation: 1s ease 0s normal forwards 1 animFadeIn;
  animation: 1s ease 0s normal forwards 1 animFadeIn;
}

.fadeUpIn {
  opacity: 0;
  -webkit-animation: 1s ease 0s normal forwards 1 animFadeUpIn;
  animation: 1s ease 0s normal forwards 1 animFadeUpIn;
}

@media (min-width: 768px) {
  .no-sm-padding {
    padding-left: 0;
    padding-right: 0;
  }

  .no-sm-padding-left {
    padding-left: 0;
  }

  .no-sm-padding-right {
    padding-right: 0;
  }

  .col-sm-5ths {
    width: 20%;
    float: left;
  }

  .break-sm {
    clear: right;
    display: block;
  }

  h1,
  .h1 {
    font-size: 32px;
    line-height: 42px;
    letter-spacing: 9.6px;
  }

  button,
  a.btn {
    font-size: 16px;
    padding: 20px 55px;
    margin-right: 20px;
  }

  header#masthead .logo {
    position: relative;
    padding-top: 30px;
    left: 45px;
  }

  header#masthead .logo svg,
  header#masthead .logo img {
    width: 158px;
    height: auto;
  }

  header#masthead nav {
    position: relative;
    top: 0;
    right: 30px;
    left: auto;
    padding: 30px calc(var(--bs-gutter-x) / 2) 20px;
    background: transparent;
    display: inline-block;
    text-align: right;
  }

  header#masthead nav img,
  header#masthead nav svg {
    height: 35px;
    margin-bottom: 13px;
  }

  header#masthead nav a {
    position: relative;
    display: inline-block;
    padding: 0;
    margin-right: 40px;
    width: auto;
    font-size: 15px;
    max-width: 110px;
    line-height: 18px;
  }

  header#masthead nav a:first-child {
    border-right: none;
  }

  header#masthead nav a:last-child {
    margin-right: 0;
  }

  header#masthead nav a span {
    position: relative;
  }

  header#masthead nav a span:after {
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    background-color: transparent;
    margin-top: 8px;
    transition: background-color 0.25s;
  }

  header#masthead nav a:hover {
    background-color: transparent;
  }

  header#masthead nav a:hover span:after {
    background-color: #fff;
  }

  header#masthead nav a:active {
    background-color: transparent;
  }

  header#masthead.blue nav a {
    color: #444444;
  }

  header#masthead.blue nav a:hover {
    color: #0076CE;
  }

  header#masthead.blue nav a:hover span:after {
    background-color: #0076CE;
  }

  header#masthead.blue svg path {
    fill: #0076CE;
  }

  body.tooltip-active .boomi-tooltip {
    transform: translate(-20px, 40px);
  }

  body.tooltip-active .boomi-tooltip::before {
    left: 20px;
    top: -10px;
    width: 23px;
    height: 23px;
  }

  body.tooltip-active .boomi-tooltip {
    transform: translate(-20px, 40px);
  }

  body.tooltip-active .boomi-tooltip::before {
    left: 20px;
    top: -10px;
    width: 23px;
    height: 23px;
  }

  section#video .dell-logo {
    width: 158px;
    left: 57px;
  }

  section#video .intel-logo {
    width: 158px;
    right: 45px;
  }

  section#video .heading {
    font-size: 20px;
  }

  section#video .octagonWrap {
    width: 100px;
    height: 100px;
    top: 63%;
  }

  section#video .arrow-right {
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    border-left: 24px solid #fff;
  }

  section#stages,
  section#cyber-stages {
    padding-top: 0;
  }

  section#stages header#masthead .replay span:after,
  section#cyber-stages header#masthead .replay span:after {
    width: 74%;
    margin: 8px auto 0;
  }

  section#stages .stages-options,
  section#cyber-stages .stages-options {
    margin: 0 auto;
    max-width: 609px;
  }

  section#stages .stages-options .title-container,
  section#cyber-stages .stages-options .title-container {
    margin-bottom: 47px;
  }

  section#stages .stages-options .title-container h1,
  section#cyber-stages .stages-options .title-container h1 {
    font-size: 32px;
    letter-spacing: 9.6px;
    white-space: nowrap;
  }

  section#stages .stages-options .title-container p,
  section#cyber-stages .stages-options .title-container p {
    font-size: 12px;
  }

  section#stages .stages-options .stages-container,
  section#cyber-stages .stages-options .stages-container {
    padding: 0;
  }

  section#stages .stages-options .stages-container .stages-pairs .stage-ind,
  section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind {
    margin-bottom: 45px;
    width: 47%;
  }

  section#stages .stages-options .stages-container .stages-pairs .stage-ind:first-child,
  section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind:first-child {
    margin-right: 45px;
  }

  section#stages .stages-options .stages-container .stages-pairs .stage-ind .video-section,
  section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind .video-section {
    background-color: #0076CE;
  }

  section#stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info,
  section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info {
    padding: 19px 0;
  }

  section#stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info p,
  section#stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info h2,
  section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info p,
  section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info h2 {
    color: #444;
    margin-bottom: 7px;
    margin-top: 7px;
  }

  section#stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info .stage-number,
  section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info .stage-number {
    font-size: 14px;
  }

  section#stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info h2,
  section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info h2 {
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  section#stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info .preview-link,
  section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info .preview-link {
    color: #0076CE;
    font-size: 16px;
  }

  section#stages .stages-options .sub-boxes-container .sub-box,
  section#cyber-stages .stages-options .sub-boxes-container .sub-box {
    margin-bottom: 45px;
    padding: 39px 0;
  }

  section#stages .stages-options .sub-boxes-container .sub-box .description,
  section#cyber-stages .stages-options .sub-boxes-container .sub-box .description {
    font-size: 14px;
  }

  section#stages .stages-options .sub-boxes-container .sub-box .title,
  section#cyber-stages .stages-options .sub-boxes-container .sub-box .title {
    font-size: 20px;
    width: 90%;
  }

  section#stages .stages-options .sub-boxes-container .sub-box .sub-box-links a,
  section#cyber-stages .stages-options .sub-boxes-container .sub-box .sub-box-links a {
    font-size: 16px;
    margin: 0 14px;
  }

  #assets .asset-page-number,
  #cyber-assets .asset-page-number {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 20px;
  }

  #assets .cyber-recovery-assets-heading,
  #cyber-assets .cyber-recovery-assets-heading {
    width: 608px;
    margin: 0 auto;
    margin-bottom: 35px;
    margin-top: 24px;
  }

  #assets h1,
  #cyber-assets h1 {
    margin-bottom: 35px;
    margin-top: 24px;
  }

  #assets .cyber-recovery,
  #cyber-assets .cyber-recovery {
    padding-top: 11px;
  }

  #assets .asset-tile-wrapper,
  #cyber-assets .asset-tile-wrapper {
    padding: 22.5px;
  }

  #assets .asset-tile,
  #cyber-assets .asset-tile {
    height: 100%;
    min-height: 246px;
  }

  #assets .asset-tile.is-tip,
  #cyber-assets .asset-tile.is-tip {
    padding: 30px 26px;
    height: auto;
    background-position: bottom 15px right 10px;
    background-size: 50px;
  }

  #assets .asset-tile.is-tip .tip-title,
  #cyber-assets .asset-tile.is-tip .tip-title {
    font-size: 14px;
    line-height: 29px;
    letter-spacing: 0.7px;
  }

  #assets .asset-tile.is-tip p,
  #cyber-assets .asset-tile.is-tip p {
    margin: 16px 0 0 0;
    font-size: 28px;
    line-height: 36px;
  }

  #assets .asset-tile a,
  #cyber-assets .asset-tile a {
    padding: 30px 22px;
  }

  #assets .asset-tile .asset-type,
  #cyber-assets .asset-tile .asset-type {
    font-size: 14px;
    line-height: 29px;
    letter-spacing: 0.7px;
  }

  #assets .asset-tile .asset-name,
  #cyber-assets .asset-tile .asset-name {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 2px;
  }

  #assets .asset-tile .download,
  #cyber-assets .asset-tile .download {
    font-size: 16px;
    line-height: 16px;
  }

  #assets .asset-tile .icon,
  #cyber-assets .asset-tile .icon {
    height: 40px;
    margin-bottom: 15px;
  }

  #assets .asset-tile .download img,
  #assets .asset-tile .download svg,
  #cyber-assets .asset-tile .download img,
  #cyber-assets .asset-tile .download svg {
    width: 19px;
  }

  #assets .next-stage,
  #cyber-assets .next-stage {
    padding: 20px;
  }

  #assets .next-stage .box,
  #cyber-assets .next-stage .box {
    margin: 25px 0;
  }

  #assets .next-stage .box main,
  #cyber-assets .next-stage .box main {
    padding: 78px 61px 67px;
  }

  #assets .next-stage .box .proceed,
  #cyber-assets .next-stage .box .proceed {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.7px;
  }

  #assets .next-stage .box .stage-name,
  #cyber-assets .next-stage .box .stage-name {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 8.4px;
  }

  #assets .sources,
  #cyber-assets .sources {
    padding: 20px;
  }

  section#cyber-quick-ref .asset-page-number {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 20px;
  }

  section#quick-ref .quick-ref-container .quick-ref-options-container,
  section#cyber-quick-ref .quick-ref-container .quick-ref-options-container {
    max-width: 631px;
  }

  section#quick-ref .quick-ref-container .quick-ref-options-container .title-container,
  section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .title-container {
    width: 100%;
  }

  section#quick-ref .quick-ref-container .quick-ref-options-container .title-container h1,
  section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .title-container h1 {
    line-height: 1.2;
    font-size: 32px;
    margin-bottom: 40px;
    letter-spacing: 7.6px;
  }

  section#quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options,
  section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options {
    gap: 45px 45px;
  }

  section#quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .video-section,
  section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .video-section {
    height: 159px;
    min-height: 159px;
  }

  section#quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .quick-ref-info,
  section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .quick-ref-info {
    padding: 0;
    height: 55%;
  }

  section#quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .quick-ref-info h2.quick-ref-title,
  section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .quick-ref-info h2.quick-ref-title {
    padding-top: 34px;
    font-size: 20px;
    letter-spacing: 2px;
    line-height: 28px;
  }

  section#quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .quick-ref-info p.preview-link,
  section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .quick-ref-info p.preview-link {
    font-size: 16px;
    padding-bottom: 27px;
  }

  section#portal {
    min-height: 100vh;
    background-position: center;
  }

  section#portal .container {
    padding-top: 133px;
  }

  section#portal .container .title-container .portal-header-eyebrow {
    font-size: 12px;
    line-height: 24px;
    margin-bottom: 3px;
  }

  section#portal .container .title-container h1 {
    margin-bottom: 37px;
    font-size: 32px;
    line-height: 54px;
    margin-top: 0;
    padding: 0;
  }

  section#portal .container .title-container .portal-header-intro {
    max-width: 608px;
    margin: 0 auto;
  }

  section#portal .portal-options {
    margin: 0 auto;
    max-width: 1263px;
    margin-top: 75px;
  }

  section#portal .portal-options .portal-pairs {
    justify-content: space-between;
    flex-direction: row;
  }

  section#portal .portal-options .portal-pairs .portal-ind {
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
    width: 282px;
  }

  section#portal .portal-options .portal-pairs .portal-ind .portal-info {
    padding: 25px 0;
  }

  section#cyber-intro .heading {
    max-width: 611px;
    letter-spacing: 7.2px;
    font-size: 24px;
    line-height: 36px;
    top: 51%;
  }

  section#cyber-intro .octagonWrap {
    width: 75px;
    height: 75px;
    top: 43%;
  }

  section#cyber-intro .dell-logo {
    width: 158px;
    left: 57px;
  }

  section#cyber-intro .vmware-logo {
    width: 106px;
    z-index: 1;
    position: absolute;
    top: 43px;
    right: 173px;
  }

  section#cyber-intro .intel-logo {
    width: 107px;
    top: 41px;
    right: 44px;
  }

  section#cyber-intro .heading {
    font-size: 20px;
  }

  section#cyber-intro .arrow-right {
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    border-left: 24px solid #fff;
  }

  #footer .footer-logo-container,
  #cyber-footer .footer-logo-container {
    padding-top: 40px;
  }

  #footer .footer-logo-container .intel-logo-footer,
  #cyber-footer .footer-logo-container .intel-logo-footer {
    width: 141px;
  }

  #footer .footer-copyright,
  #cyber-footer .footer-copyright {
    padding-top: 33px;
    padding-bottom: 15px;
  }

  #footer .footer-copyright p,
  #cyber-footer .footer-copyright p {
    font-size: 14px;
  }

  #cyber-footer .footer-logo-container {
    justify-content: center;
  }

  #cyber-footer .footer-logo-container img {
    margin: 0 12px;
    height: 21px;
  }

  #cyber-footer .footer-logo-container img.vmware-logo {
    right: 230px;
    height: 17px;
    margin-top: 5px;
  }
}

@media (min-width: 992px) {
  .no-md-padding {
    padding-left: 0;
    padding-right: 0;
  }

  .no-md-padding-left {
    padding-left: 0;
  }

  .no-md-padding-right {
    padding-right: 0;
  }

  .col-md-5ths {
    width: 20%;
    float: left;
  }

  .break-md {
    clear: right;
    display: block;
  }

  nav.stage-navigation .btn.prev-stage {
    text-align: right;
  }

  nav.stage-navigation .btn.next-stage {
    text-align: left;
  }

  body.tooltip-active .boomi-tooltip {
    width: 300px;
  }

  body.tooltip-active .graph-col .boomi-tooltip {
    width: 98%;
  }

  nav.stage-navigation .btn.prev-stage {
    text-align: right;
  }

  nav.stage-navigation .btn.next-stage {
    text-align: left;
  }

  body.tooltip-active .boomi-tooltip {
    width: 300px;
  }

  body.tooltip-active .graph-col .boomi-tooltip {
    width: 98%;
  }

  section#video .dell-logo {
    left: 57px;
  }

  section#video .dell-logo.hide,
  section#video .intel-logo.hide {
    opacity: 0;
  }

  section#video .octagonWrap {
    top: 60%;
  }

  section#stages,
  section#cyber-stages {
    padding-top: 0;
  }

  section#stages .stages-options,
  section#cyber-stages .stages-options {
    max-width: 960px;
  }

  section#stages .stages-options .stages-container .stages-pairs .stage-ind,
  section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 47%;
  }

  section#stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info h2,
  section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info h2 {
    font-size: 18px;
  }

  section#stages .stages-options .sub-boxes-container,
  section#cyber-stages .stages-options .sub-boxes-container {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
  }

  section#stages .stages-options .sub-boxes-container .sub-box,
  section#cyber-stages .stages-options .sub-boxes-container .sub-box {
    height: 100%;
  }

  section#stages .stages-options .sub-boxes-container .sub-box .sub-box-links a,
  section#cyber-stages .stages-options .sub-boxes-container .sub-box .sub-box-links a {
    margin: 0 20px;
  }

  section#quick-ref .quick-ref-container .quick-ref-options-container,
  section#cyber-quick-ref .quick-ref-container .quick-ref-options-container {
    max-width: 960px;
  }

  section#quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options,
  section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  section#portal .container {
    padding-top: 0px;
  }

  section#portal .container .title-container .portal-header-intro {
    max-width: 1000px;
    margin: 0 auto;
  }

  section#portal .portal-options {
    margin: 0 auto;
    max-width: 1263px;
    margin-top: 75px;
  }

  section#portal .portal-options .portal-pairs {
    justify-content: space-between;
  }

  section#portal .portal-options .portal-pairs .portal-ind {
    display: flex;
    flex-direction: column;
    margin-bottom: 161px;
    width: 47%;
  }

  section#portal .portal-options .portal-pairs .portal-ind:hover .portal-info {
    background-color: #0076CE;
  }

  section#portal .portal-options .portal-pairs .portal-ind:hover .portal-info p,
  section#portal .portal-options .portal-pairs .portal-ind:hover .portal-info h2,
  section#portal .portal-options .portal-pairs .portal-ind:hover .portal-info a {
    color: #fff;
  }

  section#portal .portal-options .portal-pairs .portal-ind:hover .portal-info svg path {
    fill: #fff !important;
  }

  section#portal .portal-options .portal-pairs .portal-ind:hover .video-section {
    cursor: pointer;
  }

  section#portal .portal-options .portal-pairs .portal-ind:hover .video-section .gif-preview {
    opacity: 1;
  }

  section#portal .portal-options .portal-pairs .portal-ind:hover .video-section .image-preview {
    opacity: 0;
  }

  section#portal .portal-options .portal-pairs .portal-ind:hover .video-section .octagon {
    opacity: 1;
    border: 0;
  }

  section#portal .portal-options .portal-pairs .portal-ind:hover .video-section .octagon .octagon-poly {
    animation: octo;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
  }

  section#portal .portal-options .portal-pairs .portal-ind:hover .video-section .octagon .octagon-frame {
    animation: octo-frame;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
  }

  section#portal .portal-options .portal-pairs .portal-ind:hover .video-section .octagon .octagon-play {
    animation: octo-play;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
  }

  section#portal .portal-options .portal-pairs .portal-ind .video-section {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  section#portal .portal-options .portal-pairs .portal-ind .video-section .gif-preview {
    transition: opacity 0.2s ease;
    opacity: 0;
    object-fit: cover;
    object-position: center;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  section#portal .portal-options .portal-pairs .portal-ind .video-section .image-preview {
    transition: opacity 0.2s ease;
    opacity: 1;
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
  }

  section#portal .portal-options .portal-pairs .portal-ind .video-section .octagon {
    width: 65px;
    height: 65px;
    display: block;
    position: absolute;
    background-image: url("../../images/svg/octagon.svg");
    transition: opacity 0.25s ease-in-out;
    opacity: 0;
    border: 0 !important;
  }

  section#portal .portal-options .portal-pairs .portal-ind .video-section .octagon .octagon-poly,
  section#portal .portal-options .portal-pairs .portal-ind .video-section .octagon .octagon-frame,
  section#portal .portal-options .portal-pairs .portal-ind .video-section .octagon .octagon-play {
    /*stroke: transparent;
                fill: transparent;*/
  }

  section#portal .portal-options .portal-pairs .portal-ind:first-child {
    margin-right: 45px;
  }

  section#portal .portal-options .portal-pairs .portal-ind .video-section {
    background-color: #0076CE;
  }

  section#portal .portal-options .portal-pairs .portal-ind .portal-info {
    padding: 42px 0;
    background-color: #fff;
    text-align: center;
  }

  section#portal .portal-options .portal-pairs .portal-ind .portal-info p,
  section#portal .portal-options .portal-pairs .portal-ind .portal-info h2 {
    color: #444;
    margin-bottom: 13px;
    margin-top: 0px;
  }

  section#portal .portal-options .portal-pairs .portal-ind .portal-info .portal-number {
    font-size: 14px;
  }

  section#portal .portal-options .portal-pairs .portal-ind .portal-info h2 {
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  section#portal .portal-options .portal-pairs .portal-ind .portal-info .preview-link {
    color: #0076CE;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
  }

  section#portal .portal-options .portal-pairs .portal-ind .portal-info .preview-link .launch-icon {
    display: inline-flex;
    margin-left: 10px;
  }

  section#cyber-intro .dell-logo {
    left: 57px;
  }

  section#cyber-intro .dell-logo.hide,
  section#cyber-intro .intel-logo.hide,
  section#cyber-intro .vmware-logo.hide {
    opacity: 0;
  }

  section#cyber-intro #video-endpoint .skip-button {
    right: 0;
    left: auto;
    top: 60px;
    bottom: 10px;
    width: 90px;
    height: 90px;
    z-index: 100;
  }

  section#cyber-intro #video-endpoint .octagon {
    border: none;
  }

  section#cyber-intro #video-endpoint img {
    width: 34px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    top: 15px;
  }

  section#cyber-intro #video-endpoint span {
    text-decoration: none;
    color: #fff;
    position: absolute;
    top: 55px;
    left: 50%;
    transform: translateX(-50%);
    line-height: 1em;
  }

  #footer .footer-logo-container img,
  #cyber-footer .footer-logo-container img {
    position: absolute;
    right: 50px;
  }

  #footer .footer-copyright,
  #cyber-footer .footer-copyright {
    padding-top: 33px;
    padding-bottom: 15px;
  }

  #footer .footer-copyright p,
  #cyber-footer .footer-copyright p {
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  .container-xl {
    max-width: 1580px;
    padding: 0 50px;
  }

  .no-lg-padding {
    padding-left: 0;
    padding-right: 0;
  }

  .no-lg-padding-left {
    padding-left: 0;
  }

  .no-lg-padding-right {
    padding-right: 0;
  }

  .col-lg-5ths {
    width: 20%;
    float: left;
  }

  .break-lg {
    clear: right;
    display: block;
  }

  h1,
  .h1 {
    font-size: 36px;
    line-height: 54px;
    letter-spacing: 10.8px;
  }

  header#masthead .logo {
    padding-top: 35px;
    left: 50px;
  }

  header#masthead .logo svg,
  header#masthead .logo img {
    width: 180px;
    height: auto;
  }

  header#masthead nav {
    right: 50px;
    padding: 35px calc(var(--bs-gutter-x) / 2) 0;
  }

  .styled-dropdown ul {
    max-height: 30vh;
  }

  .styled-dropdown ul {
    max-height: 30vh;
  }

  section#video .dell-logo {
    width: 180px;
    top: 35px;
    left: 62px;
    transform: none;
  }

  section#video .intel-logo {
    width: 200px;
    bottom: 57px;
    right: 50px;
    top: 55px;
  }

  section#video .heading {
    font-size: 22px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 0 0 62px;
    text-align: left;
    max-width: 500px;
    left: auto;
    letter-spacing: 8px;
  }

  section#video .octagonWrap {
    top: 50%;
  }

  #assets .cyber-recovery,
  #cyber-assets .cyber-recovery {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.9px;
    padding-top: 19px;
  }

  #assets .next-stage .box,
  #cyber-assets .next-stage .box {
    display: flex;
    flex-flow: row;
    flex-direction: row-reverse;
  }

  #assets .next-stage .box figure,
  #cyber-assets .next-stage .box figure {
    width: 50%;
    margin-bottom: 0;
    padding-bottom: 24%;
  }

  #assets .next-stage .box main,
  #cyber-assets .next-stage .box main {
    padding: 71px 20px 47px 61px;
    width: 50%;
  }

  #assets .next-stage .box .proceed,
  #cyber-assets .next-stage .box .proceed {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.7px;
  }

  #assets .next-stage .box .stage-name,
  #cyber-assets .next-stage .box .stage-name {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 8.4px;
  }

  section#cyber-quick-ref .asset-page-number {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 20px;
  }

  section#cyber-quick-ref p.cyber-recovery {
    font-size: 18px !important;
    line-height: 24px;
    letter-spacing: 0.9px;
    padding-top: 19px;
    padding-bottom: 24px;
    font-weight: 400 !important;
  }

  section#cyber-intro .dell-logo {
    width: 180px;
    top: 35px;
    left: 62px;
    transform: none;
  }

  section#cyber-intro .intel-logo {
    width: 200px;
    bottom: 57px;
    right: 50px;
    top: 90%;
  }

  section#cyber-intro .vmware-logo {
    width: 196px;
    z-index: 1;
    position: absolute;
    top: 91%;
    right: 290px;
  }

  section#cyber-intro .heading {
    font-size: 32px;
    top: 57%;
    margin: 0 auto;
    text-align: center;
    max-width: 820px;
    letter-spacing: 9.6px;
    line-height: 48px;
  }

  section#cyber-intro .octagonWrap {
    top: 41%;
    width: 100px;
    height: 100px;
  }

  #footer .footer-logo-container,
  #cyber-footer .footer-logo-container {
    padding-top: 40px;
  }

  #footer .footer-logo-container .intel-logo-footer,
  #cyber-footer .footer-logo-container .intel-logo-footer {
    width: 201px;
    right: 38px;
    height: 25px;
  }

  #footer .footer-copyright,
  #cyber-footer .footer-copyright {
    padding-top: 41px;
    padding-bottom: 20px;
  }
}

@media (min-width: 1366px) {
  section#stages .stages-options,
  section#cyber-stages .stages-options {
    max-width: 1263px;
  }

  section#stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info h2,
  section#cyber-stages .stages-options .stages-container .stages-pairs .stage-ind .stage-info h2 {
    font-size: 20px;
  }

  section#stages .stages-options .stages-container .stages-pairs .video-section,
  section#cyber-stages .stages-options .stages-container .stages-pairs .video-section {
    height: 160px;
  }

  section#stages .stages-options .title-container,
  section#cyber-stages .stages-options .title-container {
    margin-top: -65px;
  }

  section#stages .stages-options .title-container h1,
  section#cyber-stages .stages-options .title-container h1 {
    color: #444;
    font-size: 36px;
    letter-spacing: 9.6px;
    white-space: nowrap;
    margin-top: 17px;
    line-height: 1;
  }

  section#stages .stages-options .title-container p,
  section#cyber-stages .stages-options .title-container p {
    color: #444;
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 30px;
  }

  section#stages .stages-options .sub-boxes-container .sub-box,
  section#cyber-stages .stages-options .sub-boxes-container .sub-box {
    padding: 16px 0;
  }

  section#stages .stages-options .sub-boxes-container .sub-box .title,
  section#cyber-stages .stages-options .sub-boxes-container .sub-box .title {
    margin-bottom: 25px;
  }

  section#quick-ref .quick-ref-container .quick-ref-options-container,
  section#cyber-quick-ref .quick-ref-container .quick-ref-options-container {
    max-width: 1280px;
  }

  section#quick-ref .quick-ref-container .quick-ref-options-container .title-container,
  section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .title-container {
    max-width: 80%;
    margin: 0 auto;
  }

  section#quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .quick-ref-info h2.quick-ref-title,
  section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .quick-ref-info h2.quick-ref-title {
    letter-spacing: 1.2px;
  }
}

@media (min-width: 1600px) {
  h1,
  .h1 {
    font-size: 42px;
    line-height: 53px;
    letter-spacing: 12.6px;
  }

  header#masthead .logo {
    padding-top: 50px;
  }

  header#masthead .logo svg,
  header#masthead .logo img {
    width: 256px;
    height: auto;
  }

  header#masthead nav {
    padding: 50px calc(var(--bs-gutter-x) / 2) 0;
  }

  section#video .dell-logo {
    width: 256px;
    top: 50px;
    left: 62px;
    transform: none;
  }

  section#stages .stages-options,
  section#cyber-stages .stages-options {
    max-width: 1419px;
  }

  section#stages .stages-options .title-container p,
  section#cyber-stages .stages-options .title-container p {
    font-size: 18px;
  }

  section#stages .stages-options .title-container h1,
  section#cyber-stages .stages-options .title-container h1 {
    font-size: 42px;
    letter-spacing: 9.6px;
    margin-top: 21px;
  }

  section#stages .stages-options .title-container p,
  section#cyber-stages .stages-options .title-container p {
    font-size: 18px;
    margin-bottom: 0;
  }

  section#stages .stages-options .sub-boxes-container .sub-box,
  section#cyber-stages .stages-options .sub-boxes-container .sub-box {
    padding: 25px 0;
  }

  section#stages .stages-options .sub-boxes-container .sub-box .description,
  section#cyber-stages .stages-options .sub-boxes-container .sub-box .description {
    font-size: 14px;
  }

  section#stages .stages-options .sub-boxes-container .sub-box .title,
  section#cyber-stages .stages-options .sub-boxes-container .sub-box .title {
    font-size: 23px;
    letter-spacing: 2px;
    width: 90%;
  }

  section#stages .stages-options .sub-boxes-container .sub-box .sub-box-links a,
  section#cyber-stages .stages-options .sub-boxes-container .sub-box .sub-box-links a {
    font-size: 16px;
  }

  #assets .asset-page-number,
  #cyber-assets .asset-page-number {
    width: 65px;
    height: 65px;
    line-height: 65px;
    font-size: 32px;
  }

  #assets .asset-tile,
  #cyber-assets .asset-tile {
    min-height: 280px;
  }

  #assets .asset-tile.is-tip,
  #cyber-assets .asset-tile.is-tip {
    height: 100%;
  }

  #assets .asset-tile.is-tip p,
  #cyber-assets .asset-tile.is-tip p {
    font-size: 31px;
    line-height: 42px;
  }

  #assets .next-stage .box main,
  #cyber-assets .next-stage .box main {
    padding: 106px 61px 94px;
    width: 50%;
  }

  #assets .next-stage .box .proceed,
  #cyber-assets .next-stage .box .proceed {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.7px;
  }

  #assets .next-stage .box .stage-name,
  #cyber-assets .next-stage .box .stage-name {
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 8.4px;
  }

  section#cyber-quick-ref .asset-page-number {
    width: 65px;
    height: 65px;
    line-height: 65px;
    font-size: 32px;
    margin-bottom: 18px;
  }

  section#quick-ref .quick-ref-container .quick-ref-options-container,
  section#cyber-quick-ref .quick-ref-container .quick-ref-options-container {
    max-width: 1420px;
  }

  section#quick-ref .quick-ref-container .quick-ref-options-container .title-container,
  section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .title-container {
    max-width: 90%;
  }

  section#quick-ref .quick-ref-container .quick-ref-options-container .title-container h1,
  section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .title-container h1 {
    font-size: 43px;
    line-height: 53px;
    letter-spacing: 12px;
    margin-bottom: 60px;
  }

  section#quick-ref .quick-ref-container .quick-ref-options-container .title-container p,
  section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .title-container p {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
  }

  section#quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .video-section,
  section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .video-section {
    height: 180px;
    min-height: 180px;
  }

  section#quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .quick-ref-info,
  section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .quick-ref-info {
    padding: 0 20px;
  }

  section#quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .quick-ref-info h2.quick-ref-title,
  section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .quick-ref-info h2.quick-ref-title {
    padding-top: 40px;
    padding-bottom: 24px;
  }

  section#quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .quick-ref-info p.preview-link,
  section#cyber-quick-ref .quick-ref-container .quick-ref-options-container .quick-ref-options .quick-ref-ind .quick-ref-info p.preview-link {
    padding-bottom: 43px;
  }

  section#cyber-intro .dell-logo {
    width: 256px;
    top: 50px;
    left: 62px;
    transform: none;
  }
}

@media (min-width: 1700px) {
  section#video .heading {
    font-size: 36px;
    max-width: 800px;
    letter-spacing: 11px;
  }

  section#cyber-intro .heading {
    font-size: 32px;
    letter-spacing: 9.6px;
    top: 53%;
  }
}

@media (min-width: 1920px) {
  h2,
  .h2 {
    font-size: 60px;
  }

  h3,
  .h3 {
    font-size: 25px;
  }

  h4,
  .h4 {
    font-size: 15px;
  }
}

@media (max-width: 992px) {
  .panel .panel-body {
    padding: 20px;
  }

  .panel .panel-body {
    padding: 20px;
  }
}

@media (max-width: 991px) and (orientation: portrait) {
  section#video #video-endpoint {
    width: 100vw;
    background: #444444;
    height: 60px;
    display: inline-block;
    position: absolute;
    bottom: 0;
  }

  section#video #video-endpoint .skip-button {
    right: auto;
    top: 25px;
    bottom: 10px;
    width: 90px;
    height: 60px;
    left: 50%;
  }

  section#video #video-endpoint img {
    width: 25px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    top: 12px;
  }

  section#video #video-endpoint span {
    font-size: 12px;
    text-decoration: none;
    color: #fff;
    position: absolute;
    top: 42px;
    left: 50%;
    transform: translateX(-50%);
    line-height: 1em;
  }

  section#cyber-intro #video-endpoint {
    width: 100vw;
    background: #444444;
    height: 60px;
    display: inline-block;
    position: absolute;
    bottom: 0;
  }

  section#cyber-intro #video-endpoint .skip-button {
    right: auto;
    top: 25px;
    bottom: 10px;
    width: 90px;
    height: 60px;
    left: 50%;
  }

  section#cyber-intro #video-endpoint img {
    width: 25px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    top: 12px;
  }

  section#cyber-intro #video-endpoint span {
    font-size: 12px;
    text-decoration: none;
    color: #fff;
    position: absolute;
    top: 42px;
    left: 50%;
    transform: translateX(-50%);
    line-height: 1em;
  }

  section#cyber-intro #video-endpoint {
    width: 100vw;
    background: #444444;
    height: 60px;
    display: inline-block;
    position: absolute;
    bottom: 0;
  }

  section#cyber-intro #video-endpoint .skip-button {
    right: auto;
    top: 29px;
    bottom: 10px;
    width: 50px;
    height: 50px;
    left: 50%;
  }

  section#cyber-intro #video-endpoint img {
    width: 20px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    top: 4px;
  }

  section#cyber-intro #video-endpoint span {
    font-size: 12px;
    text-decoration: none;
    color: #fff;
    position: absolute;
    top: 29px;
    left: 50%;
    transform: translateX(-50%);
    line-height: 1em;
  }

  section#cyber-intro .dell-logo.hide,
  section#cyber-intro .intel-logo.hide,
  section#cyber-intro .vmware-logo.hide {
    opacity: 0;
  }
}

@media (max-width: 991px) and (orientation: landscape) {
  section#video #video-endpoint .skip-button {
    right: -40px;
  }

  section#video .dell-logo.hide,
  section#video .intel-logo.hide {
    opacity: 0;
  }
}

@media (max-width: 767px) {
  .collapsed-xs {
    height: auto;
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s ease;
  }

  .collapsed-xs.open {
    max-height: 1000px;
  }

  header#masthead .logo {
    padding: 30px 20px;
  }

  header#masthead nav {
    width: 100%;
    padding: 0;
  }

  header#masthead.break nav a:first-child {
    border-right: 1px solid #eee;
  }

  .styled-dropdown {
    margin-bottom: 20px;
  }

  .styled-dropdown {
    margin-bottom: 20px;
  }
}

@media (max-width: 380px) {
  body.tooltip-active .graph-col .boomi-tooltip {
    transform: translate(10px, -100px);
  }

  body.tooltip-active .graph-col .boomi-tooltip {
    transform: translate(10px, -100px);
  }
}

@media (max-width: 370px) {
  button,
  a.btn {
    margin-right: 5px;
  }

  #assets .next-stage .box main,
  #cyber-assets .next-stage .box main {
    padding: 22px 9px;
  }
}

@media (max-width: 349px) {
  #assets .asset-tile .download img,
  #assets .asset-tile .download svg,
  #cyber-assets .asset-tile .download img,
  #cyber-assets .asset-tile .download svg {
    display: none;
  }
}

@media (min-width: 991px) and (max-width: 1400px) {
  .styled-dropdown-col {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    padding-bottom: 25px;
  }

  .styled-dropdown-col {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
    padding-bottom: 25px;
  }
}