.boomi-tooltip {
	display: none;
}

header,
> div section,
> div section .row > div {
	transition: all .25s ease;
}

body.tooltip-active {

	> div section {
		background-color: #00070a !important;
	}

	header,
	> div section .row > div {
		opacity: 0.1;
	}

	> div section .row > div.progress-col,
	> div section .row > div.graph-col {
		opacity: 1;
	}

	.resonator {
		position: absolute;
		background-color: $brand-secondary;
		height: 24px;
		width: 24px;
		border-radius: 50%;
		color: #fff;
		text-align: center;
		box-shadow: 0 0 0 7px rgba($brand-secondary, 0.3);

		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			box-shadow: 0 0 0 0 rgba($brand-secondary, 0.2);
			animation-name: resonate;
			animation-duration: 3s;
			animation-iteration-count: infinite;

		}
	}

	ul.progressbar {

		.substep,
		.step-indicator,
		.step-spacer {
			opacity: 0.2;
		}

		.active-step .step-indicator {
			position: relative;
			box-shadow: 0 0 0 7px rgba($brand-secondary, 0.3);
			opacity: 1;

			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				width: 100%;
				height: 100%;
				border-radius: 50%;
				box-shadow: 0 0 0 0 rgba($brand-secondary, 0.2);
				animation-name: resonate;
				animation-duration: 3s;
				animation-iteration-count: infinite;

			}
		}
	}


	.boomi-tooltip {
		background: $brand-success;
		color: #fff;
		position: absolute;
		width: 54vw;
		padding: 10px;
		text-align: left;
		font-weight: 200;
		font-size: 16px;
		transform: translate(-8px, 30px);
		display: flex;
		border-radius: 2px;
		cursor: pointer;
		z-index: 1000;
		transition: opacity 0.25s ease;

		> div {
			display: block;
			width: 80%;
		}

		span {
			background-color: transparent !important;
			height: auto;
			display: inline;
			margin: 0 0 0 0 !important;
		}

		button {
			margin: 0;
			color: #fff;
			opacity: 1;
			font-weight: 200;
			font-size: 16px;
			text-transform: none;
			display: block;
			width: 20%;
			text-align: right;
			padding-right: 10px;

			&:focus,
			&:active {
				outline: none;
			}
		}

		&::before {
			content: "";
			display: block;
			position: absolute;
			left: 10px;
			top: -6px;
			width: 18px;
			height: 18px;
			border-radius: 4px;
			transform: rotate(45deg);
			background-color: $brand-success;
			z-index: -1;
		}

		&.closed {
			opacity: 0;
			pointer-events: none;
		}
	}

	.graph-col .boomi-tooltip {
		transform: translate(10px, -86px);
		width: 90%;
		max-width: 500px;

		&::before {
			top: auto;
			bottom: -8px;
			left: 61px;
		}
	}

	@keyframes resonate {
		0% {
			box-shadow: 0 0 0 0 rgba($brand-secondary, 0.2);
		}
		40% {
			box-shadow: 0 0 0 14px rgba($brand-secondary, 0.2);
		}
		100% {
			box-shadow: 0 0 0 0 rgba($brand-secondary, 0.2);
		}
	}

	// SMALL MOBILE STYLES
	@media (max-width: 380px) {
		.graph-col .boomi-tooltip {
			transform: translate(10px, -100px);
		}
	}

	// TABLET STYLES
	@media (min-width: $screen-sm-min) {
		.boomi-tooltip {
			transform: translate(-20px, 40px);

			&::before {
				left: 20px;
				top: -10px;
				width: 23px;
				height: 23px;
			}
		}
	}

	// LAPTOP STYLES
	@media (min-width: $screen-md-min) {
		.boomi-tooltip {
			width: 300px;
		}

		.graph-col .boomi-tooltip {
			width: 98%;
		}
	}


}
