section#four04 {
	height: 100vh;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50%;
	width: 100%;
	padding-top: 0;
	flex-flow: column;
	justify-content: center;

	#masthead {
		position: absolute;
		top: 0;
		left: 0;
	}

	h1 {
		letter-spacing: 12.6px;
	}
}
