$irs-height: 45px;
$irs-handle: 15px;

.irs {
  height: $irs-height;
  &.irs-with-grid { height: $irs-height + 20px; }
}

.irs-line {
  height: $irs-handle;
  top: 0;
  overflow: hidden;
  border-radius: $irs-handle;

  .irs-line-left,
  .irs-line-mid,
  .irs-line-right {
    height: $irs-handle;
    background: $gray-lighter;
  }
}

.irs-bar {
  height: $irs-handle;
  top: 0;
  background: $brand-primary;
}

.irs-shadow { display: none; }

// Handle
.irs-slider {
  width: $irs-handle;
  height: $irs-handle;
  top: 0;
  border-radius: $irs-handle;
  border: 2px solid $brand-primary;
  background: #fff;
  transition: border-color $animation-speed ease;
}

.irs-slider.state_hover,
.irs-slider:hover {
  border-color: darken($brand-primary, 10%);
}

.irs-min,
.irs-max {
  top: -20px;
}

.irs-from,
.irs-to,
.irs-single {
  top: $irs-handle + 5px;
}
