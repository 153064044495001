/* slide in from the right */
@keyframes animFadeIn {
	from { opacity: 0; }
	to { opacity: 1; }
}

@keyframes animFadeUpIn {
	from {
		transform: translateY(50px);
		opacity: 0;
	}
	to {
		transform: translateY(0);
		opacity: 1;
	}
}

.fadeIn {
	opacity: 0;
	-webkit-animation: 1s ease 0s normal forwards 1 animFadeIn;
	animation: 1s ease 0s normal forwards 1 animFadeIn;
}

.fadeUpIn {
	opacity: 0;
	-webkit-animation: 1s ease 0s normal forwards 1 animFadeUpIn;
	animation: 1s ease 0s normal forwards 1 animFadeUpIn;
}
