section#portal {
  padding-top: 0;
  background-image: url(../../images/backgrounds/Generic_BGD_3.jpg);
  background-size: cover;
  background-position: unset;

  .container {
    padding-top: 0px;
    .title-container {
      .portal-header-eyebrow {
        font-size: 12px;
        line-height: 24px;
        margin-bottom: 3px;
      }
      h1 {
        margin-bottom: 18px;
        font-size: 24px;
        line-height: 28px;
        margin-top: 5px;
        padding: 0;
      }
      .portal-header-intro {
        max-width: 1000px;
        margin: 0 auto;
      }
    }
  }

  .portal-options {
    margin: 0 auto;
    max-width: 92%;
    margin-top: 53px;
    .portal-pairs {
      justify-content: space-between;
      flex-direction: column;
      .portal-ind {
        display: flex;
        flex-direction: column;
        margin-bottom: 31px;
        width: 100%;

        &:hover {
          .portal-info {
            background-color: $brand-primary;
            p,
            h2,
            a {
              color: #fff;
            }

            svg path {
              fill: #fff !important;
            }
          }

          .video-section {
            cursor: pointer;
            .gif-preview {
              opacity: 1;
            }

            .image-preview {
              opacity: 0;
            }

            .octagon {
              opacity: 1;
              border: 0;

              .octagon-poly {
                animation: octo;
                animation-duration: 0.4s;
                animation-fill-mode: forwards;
              }
              .octagon-frame {
                animation: octo-frame;
                animation-duration: 0.4s;
                animation-fill-mode: forwards;
              }
              .octagon-play {
                animation: octo-play;
                animation-duration: 0.4s;
                animation-fill-mode: forwards;
              }
            }
          }
        }

        .video-section {
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          .gif-preview {
            transition: opacity 0.2s ease;
            opacity: 0;
            object-fit: cover;
            object-position: center;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
          }

          .image-preview {
            transition: opacity 0.2s ease;
            opacity: 1;
            object-fit: cover;
            object-position: center;
            height: 100%;
            width: 100%;
          }

          .octagon {
            width: 65px;
            height: 65px;
            display: block;
            position: absolute;
            background-image: url("../../images/svg/octagon.svg");
            transition: opacity 0.25s ease-in-out;
            opacity: 0;
            border: 0 !important;

            .octagon-poly,
            .octagon-frame,
            .octagon-play {
              /*stroke: transparent;
                fill: transparent;*/
            }
          }
        }

        &:first-child {
          margin-right: 0px;
        }

        .video-section {
          background-color: $brand-primary;
        }

        .portal-info {
          padding: 42px 0;
          background-color: #fff;
          text-align: center;
          p,
          h2 {
            color: #444;
            margin-bottom: 13px;
            margin-top: 0px;
          }

          .portal-number {
            font-size: 14px;
          }

          h2 {
            font-size: 20px;
            text-transform: uppercase;
            letter-spacing: 2px;
          }

          .preview-link {
            color: $brand-primary;
            font-size: 16px;
            display: inline-flex;
            align-items: center;
            text-decoration: none;
            .launch-icon {
              display: inline-flex;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  @media (min-width: $screen-sm-min) {
    min-height: 100vh;
    background-position: center;
    .container {
      padding-top: 133px;
      .title-container {
        .portal-header-eyebrow {
          font-size: 12px;
          line-height: 24px;
          margin-bottom: 3px;
        }
        h1 {
          margin-bottom: 37px;
          font-size: 32px;
          line-height: 54px;
          margin-top: 0;
          padding: 0;
        }
        .portal-header-intro {
          max-width: 608px;
          margin: 0 auto;
        }
      }
    }
    .portal-options {
      margin: 0 auto;
      max-width: 1263px;
      margin-top: 75px;
      .portal-pairs {
        justify-content: space-between;
        flex-direction: row;
        .portal-ind {
          display: flex;
          flex-direction: column;
          margin-bottom: 0px;
          width: 282px;
          .portal-info {
            padding: 25px 0;
          }
        }
      }
    }
  }

  @media (min-width: $screen-md-min) {
    .container {
      padding-top: 0px;
      .title-container {
        .portal-header-intro {
          max-width: 1000px;
          margin: 0 auto;
        }
      }
    }

    .portal-options {
      margin: 0 auto;
      max-width: 1263px;
      margin-top: 75px;
      .portal-pairs {
        justify-content: space-between;
        .portal-ind {
          display: flex;
          flex-direction: column;
          margin-bottom: 161px;
          width: 47%;

          &:hover {
            .portal-info {
              background-color: $brand-primary;
              p,
              h2,
              a {
                color: #fff;
              }

              svg path {
                fill: #fff !important;
              }
            }

            .video-section {
              cursor: pointer;
              .gif-preview {
                opacity: 1;
              }

              .image-preview {
                opacity: 0;
              }

              .octagon {
                opacity: 1;
                border: 0;

                .octagon-poly {
                  animation: octo;
                  animation-duration: 0.4s;
                  animation-fill-mode: forwards;
                }
                .octagon-frame {
                  animation: octo-frame;
                  animation-duration: 0.4s;
                  animation-fill-mode: forwards;
                }
                .octagon-play {
                  animation: octo-play;
                  animation-duration: 0.4s;
                  animation-fill-mode: forwards;
                }
              }
            }
          }

          .video-section {
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .gif-preview {
              transition: opacity 0.2s ease;
              opacity: 0;
              object-fit: cover;
              object-position: center;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
            }

            .image-preview {
              transition: opacity 0.2s ease;
              opacity: 1;
              object-fit: cover;
              object-position: center;
              height: 100%;
              width: 100%;
            }

            .octagon {
              width: 65px;
              height: 65px;
              display: block;
              position: absolute;
              background-image: url("../../images/svg/octagon.svg");
              transition: opacity 0.25s ease-in-out;
              opacity: 0;
              border: 0 !important;

              .octagon-poly,
              .octagon-frame,
              .octagon-play {
                /*stroke: transparent;
                fill: transparent;*/
              }
            }
          }

          &:first-child {
            margin-right: 45px;
          }

          .video-section {
            background-color: $brand-primary;
          }

          .portal-info {
            padding: 42px 0;
            background-color: #fff;
            text-align: center;
            p,
            h2 {
              color: #444;
              margin-bottom: 13px;
              margin-top: 0px;
            }

            .portal-number {
              font-size: 14px;
            }

            h2 {
              font-size: 20px;
              text-transform: uppercase;
              letter-spacing: 2px;
            }

            .preview-link {
              color: $brand-primary;
              font-size: 16px;
              display: inline-flex;
              align-items: center;
              text-decoration: none;
              .launch-icon {
                display: inline-flex;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}
