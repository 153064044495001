/* CSS3 bg cover IE support
-------------------------------------------------------- */
@mixin bg-cover($position: null) {
  background-size: cover;
  -ms-behavior: url("../../javascripts/vendor/bg-cover.htc");

  @if $position != null {
    background-position: $position;
  }
}

/* CSS3 bg contain IE support
-------------------------------------------------------- */
@mixin bg-contain($position: null) {
  background-size: contain;
  -ms-behavior: url("../../javascripts/vendor/bg-cover.htc");

  @if $position != null {
    background-position: $position;
  }
}

/* Position abolsute, fill parent element
-------------------------------------------------------- */
@mixin position-abs($offset: 0) {
  position: absolute;
  top: $offset;
  right: $offset;
  bottom: $offset;
  left: $offset;
}

/* Height hack (e.g. headers)
-------------------------------------------------------- */
@mixin height-hack {
  &,
  * {
    height: 0;
  }

  .keep-height {
    &,
    * {
      height: auto;
    }
  }
}

@mixin height-hack-undo {
  &,
  * {
    height: auto;
  }
}

/* Easily generate an overlay on an element
-------------------------------------------------------- */
@mixin dom-overlay($opacity: 0.1, $zindex: none, $color: #000) {
  &::before {
    content: "";
    display: block;

    @include position-abs;

    background: rgba($color, $opacity);

    @if $zindex != none {
      z-index: $zindex;
    }
  }
}

/* CSS blur with IE support
-------------------------------------------------------- */
@mixin blur($amount: 30) {
  filter: blur(#{$amount}px);
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius = '#{$amount}');
}

/* Generate a keylined version of a bootstrap button
-------------------------------------------------------- */
@mixin button-keyline($background) {
  // Keyline buttons
  &.btn-keyline {
    color: $background;
    background-color: transparent;
    border-color: $background;

    &:focus,
    &.focus {
      color: darken($background, 25%);
      background-color: transparent;
      border-color: darken($background, 25%);
    }

    &:hover {
      color: darken($background, 12%);
      background-color: transparent;
      border-color: darken($background, 12%);
    }

    &:active,
    &.active,
    .open > &.dropdown-toggle {
      background-image: none;
      color: darken($background, 12%);
      background-color: transparent;
      border-color: darken($background, 12%);

      &:hover,
      &:focus,
      &.focus {
        color: darken($background, 25%);
        background-color: transparent;
        border-color: darken($background, 25%);
      }
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
      &:hover,
      &:focus,
      &.focus {
        color: $background;
        border-color: $background;
      }
    }
  }
}

/* Calculate a responsive font size
-------------------------------------------------------- */
@function responsive-font-size($minimum, $maximum) {
  @return calc(
    $minimum + ($maximum - $minimum) * ((100vw - 320px) / (2860 - 320))
  );
}

/* Vertical align
-------------------------------------------------------- */
.vertical-align {
  display: table;
  width: 100%;
  height: 100%;

  > .middle {
    display: table-cell;
    vertical-align: middle;
  }

  > .bottom {
    display: table-cell;
    vertical-align: bottom;
  }
}

/* Remove WYSIWYG spacing
-------------------------------------------------------- */
.content-margins {
  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

/* Remove padding on bootstrap columns
-------------------------------------------------------- */
.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-right {
  padding-right: 0 !important;
}

/* Extra small devices (phones, 0 and up) */
.no-xs-padding {
  padding-left: 0;
  padding-right: 0;
}

.no-xs-padding-left {
  padding-left: 0;
}

.no-xs-padding-right {
  padding-right: 0;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) {
  .no-sm-padding {
    padding-left: 0;
    padding-right: 0;
  }
  .no-sm-padding-left {
    padding-left: 0;
  }
  .no-sm-padding-right {
    padding-right: 0;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) {
  .no-md-padding {
    padding-left: 0;
    padding-right: 0;
  }

  .no-md-padding-left {
    padding-left: 0;
  }
  .no-md-padding-right {
    padding-right: 0;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) {
  .no-lg-padding {
    padding-left: 0;
    padding-right: 0;
  }
  .no-lg-padding-left {
    padding-left: 0;
  }
  .no-lg-padding-right {
    padding-right: 0;
  }
}

/* Bootstrap 5th columns
-------------------------------------------------------- */
.col-xs-5ths,
.col-sm-5ths,
.col-md-5ths,
.col-lg-5ths {
  position: relative;
  min-height: 1px;
  padding-right: $grid-gutter-width/2;
  padding-left: $grid-gutter-width/2;
}

.col-xs-5ths {
  width: 20%;
  float: left;
}

@media (min-width: $screen-sm-min) {
  .col-sm-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: $screen-md-min) {
  .col-md-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: $screen-lg-min) {
  .col-lg-5ths {
    width: 20%;
    float: left;
  }
}

/* Device specific breaks
-------------------------------------------------------- */

@media (min-width: $screen-sm-min) {
  .break-sm {
    clear: right;
    display: block;
  }
}

@media (min-width: $screen-md-min) {
  .break-md {
    clear: right;
    display: block;
  }
}

@media (min-width: $screen-lg-min) {
  .break-lg {
    clear: right;
    display: block;
  }
}

/* Collapsed Panels
-------------------------------------------------------- */

@media (max-width: $screen-xs-max) {
  .collapsed-xs {
    height: auto;
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s ease;

    &.open {
      max-height: 1000px;
    }
  }
}
